import React, { useState } from "react";
import { Button } from "reactstrap";

import ChildrenModal from "components/modals/ChildrenModal";

import { DealStatus } from "constants/Constants";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";

import LiabilityTable from "./LiabilityTable";
import Liability from "./Liability";

const LiabilityTab = ({
	contactType,
	liabilities,
	editLiabilities,
	custRecNum,
}) => {
	const [currentIndex, setCurrentIndex] = useState(liabilities.length);
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
		},
	});

	// Validation for each input field
	const getSchemaObj = (obj) => {
		let schemaObj = {};

		return schemaObj;
	};

	// Define the fields to put validations on
	const schema = yup.lazy((obj) => yup.object(getSchemaObj(obj)));

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<>
			<FormProvider {...methods}>
				<div className="col">
					<ChildrenModal
						modal={modal}
						toggle={toggle}
						modalTitle={
							currentIndex === liabilities.length
								? "Add Liability"
								: "Edit Liability"
						}
						children={
							<Liability
								contactType={contactType}
								currentIndex={currentIndex}
								setCurrentIndex={setCurrentIndex}
								toggle={toggle}
								custRecNum={custRecNum}
								liabilities={liabilities}
								editLiabilities={editLiabilities}

								// contactType,
								// currentIndex,
								// setCurrentIndex,
								// toggle,
								// custRecNum,
								// liabilities,
								// editLiabilities,
							/>
						}
					/>
					<div className="col mb-2">
						<Button className="btn btn-primary btn-sm mx-3" onClick={toggle}>
							<i className="nc-icon nc-simple-add"></i> Add Loan
						</Button>
						<div className="col-md-6 text-right"></div>
					</div>
					<LiabilityTable
						setCurrentIndex={setCurrentIndex}
						toggle={toggle}
						liabilities={liabilities}
						editLiabilities={editLiabilities}
					/>
				</div>
			</FormProvider>
		</>
	);
};

export default LiabilityTab;
