import React, { useState } from "react";
import { Row, Col, CardText, Card, Button } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import ServiceContractForm from "./ServiceContractForm";
import NumberFormat from "react-number-format";
import ChildrenModal from "components/modals/ChildrenModal";
import useCurrentDealStore from "stores/DealStore";
import ServiceContractTable from "./ServiceContractTable";
import { DealStatus } from "constants/Constants";
import shallow from "zustand/shallow";
import AULButton from "features/settings/subFeatures/settingsTabs/signUp/aul/AUL";
import AgriSureButton from "features/settings/subFeatures/settingsTabs/signUp/agriSure/AgriSure";
import AmericasRVButton from "features/settings/subFeatures/settingsTabs/signUp/americasRV/AmericasRV";
import DealerLoyaltyButton from "../../../../../settings/subFeatures/settingsTabs/signUp/dealerLoyalty/DealerLoyalty";

/**
 * ServiceContractModal component displays a modal for managing service contracts.
 *
 * @param {Object} props - The component props
 * @param {boolean} props.modal - State to control the modal visibility
 * @param {Function} props.toggle - Function to toggle the modal
 *
 * @returns {JSX.Element}
 */
const ServiceContractModal = ({ modal, toggle }) => {
	const { warranty } = useCurrentSaleStore(
		(state) => ({
			warranty: state.warranty,
		}),
		shallow
	);

	const { warrantyList, type, warrantyVendors } = useCurrentDealStore(
		(state) => ({
			warrantyList: state.warranty,
			type: state.deal.type,
			warrantyVendors: state.warrantyVendors,
		}),
		shallow
	);

	const [currentIndex, setCurrentIndex] = useState(warrantyList.length);
	const [open, setOpen] = useState(false);

	/**
	 * Toggles the inner modal visibility.
	 */
	const toggleOpen = () => setOpen(!open);

	// Maps vendor options for the select input
	const vendorOption = warrantyVendors.map((obj) => ({
		label: obj.name,
		value: obj.ID,
		vendor: obj,
	}));

	return (
		<ChildrenModal
			toggle={toggle}
			modal={modal}
			modalTitle="Service Contracts"
			size="lg"
		>
			<ChildrenModal
				modal={open}
				toggle={toggleOpen}
				modalTitle="Service Contracts"
				size="lg"
			>
				<ServiceContractForm
					currentIndex={currentIndex}
					setCurrentIndex={setCurrentIndex}
					toggle={toggleOpen}
					vendorOption={vendorOption}
				/>
			</ChildrenModal>
			<ServiceContractTable
				setCurrentIndex={setCurrentIndex}
				toggle={toggleOpen}
				vendorOption={vendorOption}
			/>
			<div className="col my-3">
				<Button
					className="btn btn-primary btn-sm"
					disabled={type !== DealStatus.PENDING}
					onClick={toggleOpen}
				>
					<i className="nc-icon nc-simple-add"></i> Add Service Contract
				</Button>
			</div>
			<Card className="card-plain">
				<Row className="justify-content-center">
					<Col sm="6">
						<CardText tag="h3" className="text-center">
							Total Service Contracts
						</CardText>
					</Col>
					<div className="w-100">
						<hr className="mb-2 w-50 mx-auto" />
					</div>
					<Col sm="6">
						<CardText tag="h5" className="text-center">
							<NumberFormat
								value={warranty}
								thousandSeparator
								decimalScale={2}
								fixedDecimalScale
								prefix="$"
								displayType="text"
							/>
						</CardText>
					</Col>
				</Row>
			</Card>
			<Row>
				<AgriSureButton />
				<AULButton />
				<AmericasRVButton />
				<DealerLoyaltyButton />
			</Row>
		</ChildrenModal>
	);
};

export default ServiceContractModal;
