import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import dayjs from "dayjs";
import InputWrapper from "features/forms/components/InputWrapper";
import { updateLeadStatus } from "api/LeadApi";
import { OnlineLeadStatus, PATHS } from "constants/Constants";
import InputElement, {
	SelectorElement,
} from "features/forms/components/InputElement";
import { getVehicleDetails } from "api/InventoryAPI";
import DeleteLeadButton from "../../helperFunctions/DeleteLeadButton.js";
import Experian from "assets/img/experianLogo.png";
import Equifax from "assets/img/equifaxLogo.png";
import Transunion from "assets/img/transUnionLogo.png";
import Credit from "assets/img/700CreditLogo2.png";
import { getLeadDealerNotes, saveLead } from "api/LeadApi";
import { FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { getNotesArray } from "features/notes/NoteFunctions";
import NoteComponent from "features/notes/NoteComponent";
import PersonalData from "./PersonalData.js";
import MessageData from "./MessageData.js";
import VehicleData from "./VehicleData.js";
import LookingForData from "./LookingForData.js";
import ProfilePicture from "./ProfilePicture.js";
import PreScreenModals from "./PreScreenModals.js";
import { getMakeList, getModelList, getYearList } from "./onlineLeadUtils.js";
import { useFromPartnerLink } from "../../../../stores/LocalStorageHelper.js";
import { useHistory } from "react-router-dom";
import { object } from "prop-types";
import LiabilityTab from "../contactTabs/liabilityTab/LiabilityTab.js";
import ChildrenModal from "../../../../components/modals/ChildrenModal.js";
import { getLeadLiabilities } from "../../../../api/LiabilitiesApi.js";

const OnlineLeadDetailsPage = ({ lead = {}, toggle = () => {} }) => {
	const history = useHistory();
	// Lead store
	const dealerInfo = JSON.parse(sessionStorage.getItem("user"));
	const dealerID = dealerInfo.dealerID;
	const locationID = dealerInfo.locationID;
	const [currentLead, setCurrentLead] = useState(lead);

	const [yearList, editYearList] = useState([]);
	const [makeList, editMakeList] = useState([]);
	const [modelList, editModelList] = useState([]);
	//	const [colorList, editColorList] = useState([]);

	const [dealerNote, setDealerNote] = useState([]);
	const [leadNote, setLeadNote] = useState([]);
	const [leadVehicle, setLeadVehicle] = useState({});
	const [status, setStatus] = useState(lead?.leadStatus || 0);
	const [incID, setIncID] = useState(lead?.inventoryID);

	const [tuModal, setTuModal] = useState(false);
	const [xpnModal, setXpnModal] = useState(false);
	const [efxModal, setEfxModal] = useState(false);
	const [liabilityModal, setLiabilityModal] = useState(false);
	const [liabilities, setLiabilities] = useState([]);

	const toggleLiability = () => {
		setLiabilityModal(!liabilityModal);
	};

	const [editModal, setEditModal] = useState(false);
	const toggleEdit = () => setEditModal(!editModal);

	const tuToggle = () => {
		setTuModal(!tuModal);
	};

	const xpnToggle = () => {
		setXpnModal(!xpnModal);
	};

	const efxToggle = () => {
		setEfxModal(!efxModal);
	};

	const isCreditSignup = useFromPartnerLink("isCreditSignup");

	const schema = yup.object().shape({
		year: yup
			.date()
			.max(new Date().getFullYear() + 1)
			.min(new Date("January 01, 1900 00:00:00").getFullYear())
			.nullable()
			.transform((curr, orig) => (orig?.length === 4 ? curr : null))
			.required("Required (YYYY)"),
	});

	const methods = useFormContext({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const today = dayjs().format("YYYY-MM-DD");
	const todayTime = dayjs().format("YYYY-MM-DDTHH:mm:ss");

	let defaultLead = {
		toLocationID: locationID,
		firstName: "",
		lastName: "",
		middleName: "",
		gender: "",
		businessName: "",
		workPhone: "",
		workPhoneExt: "",
		dob: "",
		phone: "",
		cellPhone: "",
		Website: "",
		email: "",
		unit: "",
		address: "",
		city: "",
		state: "",
		zipCode: "",
		country: "",
		dateGenerated: todayTime,
		dateRead: null,
		leadStatus: 0,
		leadOrigin: null,
		leadOriginName: "",
		message: "",
		dealerNotes: "",
		priceRange: "",
		year: "",
		make: "",
		model: "",
		trim: "",
		Color: "",
		stockNumber: "",
		inventoryID: null,
		customerID: null,
		salesPersonID: null,
		salesPerson2ID: null,
		source: "",
		lastSync: null,
		insertedOn: today,
		updatedOn: today,
		driverLicense: "",
		driverLicExpDate: null,
		createdBy: "",
		xpnScore: null,
		efxScore: null,
		tuScore: null,
		credit700Url: "",
	}; // Define rules for each input field

	const handleChange = (name, value) => {
		if (name === "dealerNotes") {
			console.log(value);
			console.log(value.size);
		}
		setCurrentLead((prevLead) => ({
			...prevLead,
			[name]: value,
		}));
	};

	const htmlMessage = (currentLead?.message ?? "")
		.replace(
			/<a href=([^>]+)><b>([^<]+)<\/b><\/a>/g,
			(match, p1, p2) => `<a href="${p1}"><b>${p2}</b></a>`
		)
		.replace(/&amp;/g, "&")
		.replace(/&quot;/g, '"')
		.replace(/&lt;/g, "<")
		.replace(/&gt;/g, ">");

	const getLeadNotes = (leadID) => {
		getLeadDealerNotes(leadID).then(
			(res) => {
				console.log(res.data);
				console.log(res.data.content.DealerNotes);
				const container = res.data.content.DealerNotes.map((obj) => {
					console.log(obj);
					const note = (
						<>
							<div>
								{dayjs(obj.createdOn).format("MM/DD/YYYY HH:MM") +
									" " +
									obj.description}
							</div>
						</>
					);
					return note;
				});

				setLeadNote(container);
			},
			(err) => {
				console.log(err);
			}
		);
	};

	const saveNotes = () => {
		let tempNote = dealerNote.length > 0 ? JSON.stringify(dealerNote) : "";
		//setDealerNote(dealerNote);
		handleChange("dealerNotes", tempNote);
	};

	const getVehicleData = (inventoryID) => {
		inventoryID != null &&
			getVehicleDetails(inventoryID).then(
				(res) => {
					console.log(res.data.content);
					setLeadVehicle(res.data.content);
				},
				(err) => {
					console.log(err);
				}
			);
	};

	const scoreHandler = (score) => {
		if (score === null || score === undefined) {
			return "None";
		} else if (score >= 0) {
			return score;
		} else {
			return "None";
		}
	};

	useEffect(() => {
		getYearList({ editYearList });
		getMakeList({ editMakeList });
		getLeadNotes(lead?.leadID);
		setDealerNote(getNotesArray(lead?.dealerNotes));
		if (lead && lead?.leadID) {
			setIncID(lead?.inventoryID);
			getLeadLiabilities(lead?.leadID).then(
				(res) => {
					console.log(res);
					setLiabilities(res.data.content);
				},
				(err) => {
					console.log(err);
				}
			);
		} else {
			setCurrentLead(defaultLead);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getModelList({ editModelList });
		// eslint-disable-next-line
	}, [lead.make]);

	useEffect(() => {
		getVehicleData(incID);
		handleChange("inventoryID", incID);
		// eslint-disable-next-line
	}, [incID]);

	useEffect(() => {
		setDealerNote(getNotesArray(lead?.dealerNotes));
		// eslint-disable-next-line
	}, [lead?.dealerNotes]);

	useEffect(() => {
		if (lead?.leadID !== null) {
			updateLeadStatus(lead?.leadID, status);
		}
		handleChange("leadStatus", status);

		// eslint-disable-next-line
	}, [status]);

	const LeadSaveButton = () => {
		return (
			<>
				<div
					className="btn btn-success float-button"
					onClick={() => {
						let l = { ...currentLead };
						if (currentLead.leadID == null) {
							l.dateGenerated = todayTime;
							l.insertedOn = today;
							l.updatedOn = today;
						}
						saveLead(l);
						toggle();
						history.push(PATHS.CUSTOMER_LIST);
					}}
				>
					<i className="nc-icon nc-check-2" /> Save
				</div>
			</>
		);
	};
	console.log(lead);
	console.log(lead == null);
	return (
		<>
			{lead && Object.keys(lead).length === 0 && (
				<Row className="pb-2 pt-3 text-center ">
					<CardTitle>
						<h2>Add Lead</h2>
					</CardTitle>
				</Row>
			)}
			<Row className="justify-content-center">
				<div className="col-xl-2">
					<Card className="cursor-pointer">
						<CardBody
							onClick={() => xpnToggle(xpnModal, setXpnModal)}
							className="text-center"
						>
							<img src={Experian} alt="experian" className="rounded" />
							Score: {scoreHandler(lead?.xpnScore)}
						</CardBody>
					</Card>
				</div>
				<div className="col-xl-2">
					<Card className="cursor-pointer">
						<CardBody
							onClick={() => tuToggle(tuModal, setTuModal)}
							className="text-center"
						>
							<img src={Transunion} alt="transunion" className="rounded" />
							Score: {scoreHandler(lead?.tuScore)}
						</CardBody>
					</Card>
				</div>
				<div className="col-xl-2">
					<Card className="cursor-pointer">
						<CardBody
							onClick={() => efxToggle(efxModal, setEfxModal)}
							className="text-center"
						>
							<img src={Equifax} alt="equifax" className="rounded" />
							Score: {scoreHandler(lead?.efxScore)}
						</CardBody>
					</Card>
				</div>

				<PreScreenModals
					xpnToggle={xpnToggle}
					xpnModal={xpnModal}
					Credit={Credit}
					lead={lead}
					tuModal={tuModal}
					efxModal={efxModal}
					tuToggle={tuToggle}
					efxToggle={efxToggle}
				/>
				{!isCreditSignup ? (
					<div className="col-xl-4">
						<Card className="cursor-pointer">
							<a
								href="https://700credit.com/dealerclick"
								target="_blank"
								rel="noopener noreferrer"
								className=""
							>
								<CardBody>
									<img src={Credit} alt="700credit" className="rounded" />
									<div className="p text-center">Sign Up</div>
								</CardBody>
							</a>
						</Card>
					</div>
				) : (
					" "
				)}
			</Row>
			<Row>
				<Col>
					<SelectorElement
						disableValidation={true}
						value={status}
						name="LeadStatus"
						label="Status"
						onChange={(e) => setStatus(Number(e.target.value))}
						options={OnlineLeadStatus}
					/>
				</Col>
				<div></div>
				<div>
					<Row> </Row>
					<Row> </Row>
					<Button
						className="btn btn-primary btn-sm"
						onClick={() => toggleLiability()}
					>
						<i className="nc-icon nc-simple-add"></i> Liability
					</Button>
				</div>
			</Row>
			<ChildrenModal
				modal={liabilityModal}
				toggle={toggleLiability}
				modalTitle="Liability Tab"
				children={
					<LiabilityTab
						liabilities={liabilities}
						editLiabilities={setLiabilities}
						custRecNum={lead?.leadID}
						contactType="Lead"
					/>
				}
			/>
			<FormProvider {...methods}>
				<InputWrapper
					formTitle="Person"
					inputComponents={[
						<PersonalData
							currentLead={currentLead}
							setCurrentLead={setCurrentLead}
							methods={methods}
							handleChange={handleChange}
						/>,
					]}
				/>
				<br />
				<InputWrapper
					formTitle="Current Auto Loans"
					inputComponents={[
						<LiabilityTab
							liabilities={liabilities}
							editLiabilities={setLiabilities}
							custRecNum={lead?.leadID}
							contactType="Lead"
						/>,
					]}
				/>
				<br />
				<InputWrapper
					formTitle="Looking For"
					inputComponents={[
						<LookingForData
							currentLead={currentLead}
							handleChange={handleChange}
							methods={methods}
							yearList={yearList}
							makeList={makeList}
							modelList={modelList}
						/>,
					]}
				/>
				<br />
				<InputWrapper
					formTitle="Vehicle"
					inputComponents={[
						<VehicleData setIncID={setIncID} leadVehicle={leadVehicle} />,
					]}
				/>
				<br />
				<InputWrapper
					formTitle="Lead Note"
					inputComponents={[
						<MessageData currentLead={currentLead} htmlMessage={htmlMessage} />,
					]}
				/>
				<br />
				<InputWrapper formTitle="Dealer Notes" inputComponents={leadNote} />
				<br />
				<NoteComponent
					notes={dealerNote}
					editNotes={setDealerNote}
					saveNotes={saveNotes}
				/>
				<br />
				<ProfilePicture
					dealerID={dealerID}
					locationID={locationID}
					lead={lead}
				/>
				<DeleteLeadButton IDs={lead?.leadID} toggle={toggle} />
				<LeadSaveButton />
				<div>{lead?.leadID}</div>
			</FormProvider>
		</>
	);
};

export default OnlineLeadDetailsPage;
