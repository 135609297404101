import React, { useContext, useEffect } from "react";
import shallow from "zustand/shallow";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";

import useRoute from "hooks/useRoute";
import useCurrentVehicleStore from "stores/InventoryStore";
import useCurrentPhotoStore from "stores/PhotoStore";
import InventoryDetailsTab from "../subFeatures/inventoryTabs/detailsTab/InventoryDetailsTab";
import InventoryPhotosTab from "../subFeatures/inventoryTabs/photosTab/InventoryPhotosTab";
import InventoryCostsTab from "../subFeatures/inventoryTabs/costsTab/InventoryCostsTab";
import InventoryKbbTab from "../subFeatures/inventoryTabs/valuesTab/InventoryKbbTab";
import NavBar from "components/tabs/TabsHolder";
import Loading from "components/loadingSpinner/Loading.js";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import { getAllVendorSortedByLocationID } from "api/VendorsAPI";
import { failAlert } from "utils/alertUtils";
import { getAllInvDetails } from "api/InventoryAPI";
import {
	KbbContext,
	KbbValuesProvider,
} from "../subFeatures/inventoryTabs/valuesTab/KbbContext";
import { PATHS } from "constants/Constants";
import { getThumbnailURL } from "../subFeatures/inventoryTabs/photosTab/helperFunctions";
import InventoryFormsTab from "../subFeatures/inventoryTabs/formsTab/InventoryFormsTab";
// import InventoryFlooringTab from "../../features/inventory/subFeatures/inventoryTabs/InventoryFlooringTab";
import InventoryHistoryTab from "../subFeatures/inventoryTabs/historyTab/InventoryHistoryTab";
import InventoryActionBar from "./InventoryActionBar";
import dayjs from "dayjs";
import InventoryTitle from "../subFeatures/inventoryTabs/titleTab/InventoryTitle";
import { TemplateVariableProvider } from "context/TemplateVariableContext";
import InitializeTemplateVars from "utils/initializeTemplateVars";
import InventoryValuesTab from "../subFeatures/inventoryTabs/valuesTab/InventoryValuesTab";
import { MmrContext, MMRValuesProvider } from "../../../context/MMRContext";
import useIsOwnerOrAdmin from "../../settings/subFeatures/settingsTabs/userManagement/useIsOwnerOrAdmin";
import UnauthorizedContent from "../../deal/subFeatures/dealTabs/tabsContainer/UnauthorizedContent";

const isAppraisalPage = window.location.pathname === "/inventory-appraisal";

const InventoryDetails = () => {
	const history = useHistory();
	const newRoute = useRoute(PATHS.INVENTORY_LIST);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");
	const dcLoginID = useFromUser("ID");
	const today = dayjs().format("YYYY-MM-DD");
	const isOwnerOrAdmin = useIsOwnerOrAdmin();

	// kbb vehicle values
	const {
		stockNo,
		vin,
		year,
		make,
		model,
		odometerIn,
		editOdometerIn,
		editVin,
		editYear,
		editMake,
		editModel,
	} = useCurrentVehicleStore((state) => state, shallow);

	const { inventoryID } = useCurrentVehicleStore((state) => state, shallow);

	// Vehicle store
	const {
		isLoading,
		setInventoryFields,
		editIsLoadingFalse,
		reset,
		editVendorsList,
		editReconVendorsList,
		editFlooringVendorsList,
		editCostHist,
		editCostDescList,
		editDepartmentsList,
		editAllVendorList,
		editLocationsList,
		editBuyersList,
		editTradeLenderList,
		editThumbnail,
		editModifiedOn,
		editModifiedByID,
		editOpenedBy,
		// different
		isVinLoading,
	} = useCurrentVehicleStore((state) => state, shallow);

	const { setDcKbbObj } = useContext(KbbContext);
	const { setVehicleMMRValues, setDefaultMMR } = useContext(MmrContext);

	const vehicleKbbObj = {
		stockNo,
		vin,
		year,
		make,
		model,
		odometerIn,
		editOdometerIn,
		editVin,
		editYear,
		editMake,
		editModel,
	};

	// Photo store
	const { resetImages } = useCurrentPhotoStore(
		(state) => ({
			resetImages: state.reset,
		}),
		shallow
	);

	// Gets all the inventory related details
	const getInvDetails = async () => {
		console.log(history);
		const vehicleID = inventoryID || history.location.state?.ID;
		console.log("Getting inventory details for: " + inventoryID);
		console.log("Getting inventory details for: " + vehicleID);

		if (vehicleID != null) {
			const allInfo = getAllInvDetails(dealerID, locationID, vehicleID);
			const allVendors = getAllVendorSortedByLocationID(locationID);

			Promise.all([allInfo, allVendors]).then(
				(res) => {
					updateStores(res);
					console.log(res);
				},
				(err) => {
					console.log(err);
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						history.push(newRoute);
					}
				}
			);
		} else if (vehicleID == null) {
			history.push(newRoute);
			failAlert("Missing ID");
		} else {
			history.push(newRoute);
			retrieveFailAlert();
		}
	};

	// Updates relevant store/context values with API call
	const updateStores = (res) => {
		const invInfo = res[0].data.content;
		const allVendors = res[1].data.content;
		console.log(invInfo);
		console.log(invInfo.vehicleLocations);

		// Edit vehicle details tab
		setInventoryFields(invInfo.vehicle);
		setDcKbbObj(invInfo.kbb);
		if (invInfo.mmr === null) {
			setDefaultMMR();
		} else {
			setVehicleMMRValues(invInfo.mmr);
		}

		// Edit costs tab
		editCostHist(invInfo.reconditioning.reconditioningList);
		editCostDescList(invInfo.reconditioning.description);
		editDepartmentsList(invInfo.reconditioning.department);
		editLocationsList(invInfo.vehicleLocations);
		editBuyersList(invInfo.vehicleBuyers);
		// Edit vendors arrays
		editAllVendorList(allVendors);
		editVendorsList(allVendors.vendors);
		editReconVendorsList(allVendors.reconSupplier);
		editFlooringVendorsList(allVendors.flooringVendor);
		editTradeLenderList(allVendors.lenders);

		editThumbnail(getThumbnailURL(invInfo.images));

		//edit more information
		editModifiedOn(today);
		editModifiedByID(dcLoginID);
		editOpenedBy(lastName + ", " + firstName);
		editIsLoadingFalse();
	};

	useEffect(() => {
		getInvDetails();
		return () => {
			reset();
			resetImages();
		};
		// eslint-disable-next-line
	}, []);

	const tabs = [
		"Details",
		"Photos",
		"Costs",
		"Values",
		// "Flooring",
		"Forms",
		"History",
	];

	const tabComponents = [
		<InventoryDetailsTab />,
		<InventoryPhotosTab />,
		isOwnerOrAdmin ? <InventoryCostsTab /> : <UnauthorizedContent />,
		<InventoryValuesTab
			inventoryID={inventoryID}
			vehicleKbbObj={vehicleKbbObj}
			showVinDecoder={isAppraisalPage}
		/>,
		// <InventoryKbbTab
		// 	inventoryID={inventoryID}
		// 	vehicleKbbObj={vehicleKbbObj}
		// 	showVinDecoder={isAppraisalPage}
		// />,
		// <InventoryFlooringTab />,
		<InventoryFormsTab />,
		<InventoryHistoryTab />,
	];

	return (
		<div style={{ minHeight: "1000px" }}>
			{isLoading && <Loading containerStyle={{ height: "80vh" }} />}
			{!isLoading && (
				<div className="col-md-12 px-0">
					<InventoryActionBar />
					{!isVinLoading && (
						<>
							<InventoryTitle />
							<NavBar
								navClass="nav-pills-primary justify-content-center nav nav-pills"
								tabs={tabs}
								tabComponents={tabComponents}
							/>
						</>
					)}
				</div>
			)}
		</div>
	);
};

const Providers = ({ children }) => {
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			// eslint-disable-next-line
			min: "Must be ${min} characters or more",
			// eslint-disable-next-line
			length: "Must be ${length} numbers long",
			matches: "Only numbers and letters are allowed",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		model: yup.string().required().max(50),
		make: yup.string().required().max(50),
		vin: yup.string().required(),
		dateIn: yup.string().required().length(10, "Required format is YYYY/MM/DD"),
		year: yup
			.date()
			.max(new Date().getFullYear() + 1)
			.min(new Date("January 01, 1900 23:15:00").getFullYear())
			.nullable()
			.transform((curr, orig) => (orig?.length === 4 ? curr : null))
			.required("Required (YYYY)"),
		stockNo: yup.string().required().min(1).max(12),
		// .matches(/^[a-zA-Z0-9]*$/),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<KbbValuesProvider>
			<TemplateVariableProvider>
				<MMRValuesProvider>
					<InitializeTemplateVars />
					<FormProvider {...methods}>{children}</FormProvider>
				</MMRValuesProvider>
			</TemplateVariableProvider>
		</KbbValuesProvider>
	);
};

const InventoryDetailPage = () => {
	return (
		<Providers>
			<InventoryDetails />
		</Providers>
	);
};

export default InventoryDetailPage;
