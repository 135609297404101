import React, { useEffect } from "react";

import useCurrentDealStore from "stores/DealStore";
import useCurrentRecapStore from "stores/RecapStore";
import useCurrentSaleStore from "stores/SaleStore";
import useRoute from "hooks/useRoute";
import Loading from "components/loadingSpinner/Loading.js";

import { getDealDetails } from "api/DealAPI";
import { getSettings } from "api/SettingsAPI";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert, failAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import { useHistory } from "react-router-dom";
import { PATHS, SaleType } from "constants/Constants";

import shallow from "zustand/shallow";
import useSetDeal from "../hooks/useSetDeal";
import DealTabs from "../subFeatures/dealTabs/tabsContainer/DealTabs";
import useBuildDeal from "../hooks/useBuildDeal";

const DealDetailPage = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const dcLoginID = useFromUser("ID");
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");
	const history = useHistory();
	const dealListPage = useRoute(PATHS.DEAL_LIST);
	//	const dealAddPage = useRoute(PATHS.DEAL_ADD);
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	// Deal store
	const {
		ID,
		isLoading,
		resetDeal,
		editIsLoading,
		updateSettings,
		editModifiedBy,
		editModifiedById,
	} = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			isLoading: state.isLoading,
			resetDeal: state.reset,
			editIsLoading: state.editIsLoading,
			updateSettings: state.updateSettings,
			editModifiedBy: state.editModifiedBy,
			editModifiedById: state.editModifiedById,
		}),
		shallow
	);

	// Recap store
	const { resetRecap } = useCurrentRecapStore(
		(state) => ({
			resetRecap: state.reset,
		}),
		shallow
	);

	// Sale store
	const { resetSale } = useCurrentSaleStore(
		(state) => ({
			resetSale: state.reset,
		}),
		shallow
	);

	const getData = () => {
		const dealID = ID || history.location.state?.ID;
		if (dealID != null) {
			Promise.all([
				getSettings(dealerID, locationID),
				getDealDetails(dealID),
			]).then(
				(res) => {
					console.log("getData() has finished");
					console.log(res);
					console.log("Getting Deal details for: " + dealID);
					const defaultSettings = res[0].data.content;
					const settingsFromDeal = JSON.parse(
						res[1].data.content.deal.dealSettings
					);

					const resSaleType = res[1].data.content.deal.sale.saleType;
					// only open retail and wholesale deals
					console.log({ resSaleType });
					if (
						resSaleType !== SaleType[0].value &&
						resSaleType !== SaleType[1].value &&
						resSaleType !== SaleType[2].value &&
						resSaleType !== SaleType[3].value &&
						resSaleType !== SaleType[4].value
					) {
						failAlert(
							"This type of deal is under construction. DealerClick is working on it right now."
						);
						history.push(dealListPage);
					} else {
						setDeal(res[1].data.content);
						updateSettings(defaultSettings, settingsFromDeal);
						editModifiedBy(lastName + ", " + firstName);
						editModifiedById(dcLoginID);
						editIsLoading(false);
					}
				},
				(err) => {
					console.log(err);
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						history.push(dealListPage);
					}
				}
			);
		} else {
			const deal = getDeal();
			console.log(deal);
			setDeal(deal);
			editIsLoading(false);
			//	history.push(dealAddPage);
		}
	};
	// console.log(deal);
	useEffect(() => {
		if (isLoading) {
			console.log("loading deal");
			getData();
		}

		return () => {
			resetDeal();
			resetRecap();
			resetSale();
		};
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div style={{ minHeight: "1000px" }}>
				{(isLoading && <Loading containerStyle={{ height: "80vh" }} />) || (
					<DealTabs />
				)}
			</div>
		</>
	);
};

export default DealDetailPage;
