import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Row } from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useForm, FormProvider, Controller } from "react-hook-form";
import shallow from "zustand/shallow";
import { useFromUser } from "stores/LocalStorageHelper";
import InputElement, {
	CurrencyInputElement,
} from "features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";
import useCurrentDealStore from "stores/DealStore";
import { DealStatus } from "constants/Constants";
import useBuildDeal from "../../../hooks/useBuildDeal";
import { WholesaleInvsContext } from "./WholeSaleInvsContext";
import OpenModalButton from "../../../components/OpenModalButton";
import ChildrenModal from "../../../../../components/modals/ChildrenModal";
import DealInventoryListData from "../../inventory/DealInventoryListData";

const WholeSaleInvsForm = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const { wholesaleInvs = [], editWholesaleInvs } = useCurrentDealStore(
		(state) => state,
		shallow
	);
	const { ID, type } = useCurrentDealStore((state) => state.deal, shallow);
	const {
		inventoryID,
		editInventoryID,
		bulkVehicle,
		editBulkVehicle,
		dealId,
		editdealId,
		salesPrice,
		editSalesPrice,
		modalActive,
		setModalActive,
		toggleWholesaleInvsModal,
		currentIndex,
		setCurrentIndex,
		isLoading = true,
		editIsLoading,
	} = useContext(WholesaleInvsContext);

	const [stockNo, setStockNo] = useState("");
	const [vin, setVin] = useState("");
	const [year, setYear] = useState("");
	const [make, setMake] = useState("");
	const [model, setModel] = useState("");
	const getDeal = useBuildDeal();
	const [add, setAdd] = useState(false);

	const toggleAdd = () => {
		setAdd(!add);
		setCurrentIndex(wholesaleInvs.length || 0); // Default to 0 if wholesaleInvs is empty
	};
	useEffect(() => {
		console.log(bulkVehicle);
		if (!isLoading) {
			editIsLoading(false);
			//			editInventoryID(inventoryID);
			//editBulkVehicle(bulkVehicle);
			setVin(bulkVehicle.vin);
			setStockNo(bulkVehicle.stockNo);
			setYear(bulkVehicle.year);
			setMake(bulkVehicle.make);
			setModel(bulkVehicle.model);
			editSalesPrice(salesPrice);
		}
		//editDateIn(today);
		// eslint-disable-next-line
	}, [inventoryID]);
	useEffect(() => {
		console.log(isLoading);
		console.log(currentIndex);
		console.log(wholesaleInvs[currentIndex]);
		if (currentIndex < wholesaleInvs.length) {
			editBulkVehicle(wholesaleInvs[currentIndex]);
			setVin(wholesaleInvs[currentIndex].car.vin);
			setStockNo(wholesaleInvs[currentIndex].car.stockNo);
			setYear(wholesaleInvs[currentIndex].car.year);
			setMake(wholesaleInvs[currentIndex].car.make);
			setModel(wholesaleInvs[currentIndex].car.model);
			//editdealId(dealId);
			editSalesPrice(wholesaleInvs[currentIndex].salesPrice);
		} else {
			clearFields();
		}
		editIsLoading(false);
	}, []);

	const clearFields = () => {
		editInventoryID(null);
		setStockNo("");
		setVin("");
		setYear("");
		setMake("");
		setModel("");
		editSalesPrice(0);
	};
	const getID = () => {
		if (
			!Array.isArray(wholesaleInvs) || // Ensure wholesaleInvs is an array
			wholesaleInvs[currentIndex] === undefined // Check index bounds
		) {
			return null;
		}

		if (wholesaleInvs[currentIndex].id === undefined) {
			return null;
		}

		return wholesaleInvs[currentIndex].id;
	};
	const addWholesaleInvs = () => {
		console.log(salesPrice);
		const wholesaleInvsId = getID();
		const newWholesaleInvs = {
			id: wholesaleInvsId,
			dealRecNum: ID,
			inventoryID: inventoryID,
			salesPrice: parseFloat(salesPrice),
			dealerID: dealerID,
			locationID: locationID,
			car: {
				inventoryID: inventoryID,
				vin: vin,
				make: make,
				model: model,
				year: year,
				stockNo: stockNo,
			},
		};
		const wholesaleInvsList = () => {
			let obj = [...wholesaleInvs];
			obj[currentIndex] = newWholesaleInvs;
			return obj;
		};

		const newWholesale = wholesaleInvsList();
		editWholesaleInvs(newWholesale);
		const deal = getDeal();
		deal.wholesaleInvs = newWholesale;
		toggleWholesaleInvsModal();
	};

	// Define rules for each input field
	const schema = yup.object().shape({});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	//Condition is   locked

	return (
		<FormProvider {...methods}>
			<Form>
				<Row>
					<OpenModalButton
						buttonClass="btn-md btn btn-link"
						buttonText="Select Vehicle"
						modalTitle="Vehicles"
						onClick={toggleAdd}
						icon={true}
						iconClass="nc-icon nc-tap-01"
					/>
				</Row>

				<InputWrapper
					formTitle="Wholesale vehicle Details"
					inputComponents={[
						<InputElement
							{...methods}
							value={stockNo}
							name="stockNo"
							label="Stock Number"
							type="text"
							onChange={(e) => setStockNo(e.target.value)}
							readOnly={true}
						/>,
						<InputElement
							{...methods}
							value={vin}
							name="vin"
							label="VIN"
							type="text"
							onChange={(e) => {
								setVin(e.target.value);
							}}
							readOnly={true}
						/>,
						<InputElement
							{...methods}
							value={year}
							name="year"
							label="Year"
							type="text"
							onChange={(e) => {
								setYear(e.target.value);
							}}
							readOnly={true}
						/>,
						<InputElement
							{...methods}
							value={make}
							name="make"
							label="Make"
							type="text"
							onChange={(e) => {
								setMake(e.target.value);
							}}
							readOnly={true}
						/>,
						<InputElement
							{...methods}
							value={model}
							name="model"
							label="Model"
							type="text"
							onChange={(e) => {
								setModel(e.target.value);
							}}
							readOnly={true}
						/>,
						<CurrencyInputElement
							value={salesPrice}
							name="price"
							label="Price"
							type="text"
							onChange={editSalesPrice}
						/>,
					]}
					buttons={
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Button
								className="btn-link btn-md"
								readOnly={type !== DealStatus.PENDING}
								onClick={methods.handleSubmit(addWholesaleInvs)}
							>
								<icon className="nc-icon nc-check-2" /> Save
							</Button>
							<Button
								readOnly={type !== DealStatus.PENDING}
								onClick={clearFields}
								className="btn-link btn-md"
							>
								Clear
							</Button>
						</div>
					}
				/>
			</Form>
			<ChildrenModal
				modalTitle="Select Vehicle"
				modal={add}
				toggle={toggleAdd}
				children={<DealInventoryListData toggle={toggleAdd} mainForm="Bulk" />}
			/>
		</FormProvider>
	);
};
export default WholeSaleInvsForm;
