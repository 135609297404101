import React, { useState, useEffect } from "react";
import { useDigitzsMerchantId, useFromUser } from "stores/LocalStorageHelper";
import dayjs from "dayjs";
import { Button, Col, Form, Row } from "reactstrap";
import InputElement, {
	CurrencyInput,
} from "../features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import { SelectorElement } from "../features/forms/components/InputElement";
import {
	PaymentMode,
	PaymentStatus,
	PaymentType,
	PaymentTypeToModeMapping,
	PmtMode,
	PmtType,
	paymentMethod,
} from "../constants/Constants";
import AccountHook from "./AccountHook";
import ReceiptButton from "./ReceiptButton";
import ListItemModal from "../features/settings/subFeatures/settingsTabs/signUp/ListItemModal";
import ChildrenModal from "../components/modals/ChildrenModal";
import DigitzsPayment from "../services/digitzsService/DigitzsPayment";
import DigitzsImg from "assets/img/digitzs-final-logo.png";
import Digitzs from "../features/settings/subFeatures/settingsTabs/signUp/digitzs/Digitzs";

const AccountPaymentForm = (props) => {
	// const tax = (acct.getIsTaxDeferred()
	// 		? payment.getAmtPaid().multiply(BigDecimal.valueOf(acct.getSalesTaxRate())).setScale(2,
	// 				RoundingMode.HALF_UP)
	// 		: BigDecimal.ZERO);

	const save = AccountHook();
	const { currentIndex, toggle } = props;
	const { payments, editPayments, account } = useCurrentAccountStore(
		(state) => state,
		shallow
	);
	console.log(account);
	console.log(props);
	const today = dayjs().format("YYYY-MM-DD");
	console.log(today);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const userID = useFromUser("ID");

	const [payment, setPayment] = useState(payments[currentIndex]);
	const [datePaid, setdatePaid] = useState(today);
	const [amtPaid, setAmtPaid] = useState(0);

	const [receiptNo, setreceiptNo] = useState("");
	const [method, setMethod] = useState("cash");

	const [checkNo, setCheckNo] = useState("");
	const [pmtType, setPmtType] = useState("Loan");
	const [pmtMode, setPmtMode] = useState(0);
	const [status, setStatus] = useState("completed");
	const [statusReason, setStatusReason] = useState("");
	const [note, setNote] = useState("");
	const [description, setDescription] = useState("");

	const [newPmt, setNewPmt] = useState(true);

	const [filteredPaymentMode, setFilteredPaymentMode] = useState([]);
	const [formTitle, setFormTitle] = useState([]);

	const [lateFeePaid, setLateFeePaid] = useState(0);
	const [principalPaid, setPrincipalPaid] = useState(0);
	const [interestPaid, setInterestPaid] = useState(0);
	//	const [debitPaid, setDebitPaid] = useState(0);
	const [taxPaid, setTaxPaid] = useState(0);
	const [adjFeePaid, setAdjFeePaid] = useState(0);
	const [pickupPaid, setPickupPaid] = useState(0);

	//const [userRecNum ,setUserRecNum] = useState();

	const dailyIntRate = account.apr / 365 / 100;

	const handleMethod = (e) => {
		setMethod(e);
		// if (
		// 	e === "check" ||
		// 	e === "cashierCheck" ||
		// 	e === "echeck" ||
		// 	e === "other"
		// )
		// 	sethideCheck(false);
		// else sethideCheck(true);
	};

	const merchantId = useDigitzsMerchantId();
	const [digitzsModal, setDigitzsModal] = useState(false);
	const toggleDigitzsModal = () => setDigitzsModal(!digitzsModal);
	const toggleDigitzsModalApi = () => {
		if (merchantId !== null) {
			failAlert(
				"Digitzs account already exists for this dealership with merchant ID: " +
					res.data.content.merchantId
			);
			return;
		}
		setDigitzsModal(!digitzsModal);
	};

	useEffect(() => {
		console.log("Current index is: " + currentIndex);
		console.log("payments length is: " + payments.length);
		console.log(payments[currentIndex]);
		console.log(payment);
		if (currentIndex < payments.length) {
			setNewPmt(false);
			setFormTitle("Edit Payment ");

			setdatePaid(payment?.datePaid || "");
			setAmtPaid(payment?.amtPaid || "");

			setreceiptNo(payment?.receiptNo || "");
			setCheckNo(payment?.checkNo);
			setMethod(payment?.method);
			setPmtType(payment?.pmtType);
			setPmtMode(payment?.pmtMode);
			setNote(payment?.note);
			setDescription(payment?.description);
			setStatus(payment?.status);
			setStatusReason(payment?.statusReason);

			setLateFeePaid(payment?.lateFeepaid || 0.0);
			setPrincipalPaid(payment?.principalPaid || 0.0);
			setInterestPaid(payment?.interestPaid || 0.0);
			//			setDebitPaid(payment?.debitPaid || 0.0);
			setTaxPaid(payment?.taxPaid || 0.0);
			setAdjFeePaid(payment?.adjFeePaid || 0.0);
			setPickupPaid(payment?.pickupPaid || 0.0);
		} else {
			console.log(payment?.datePaid);
			const newPayment = {
				acctRecnum: account.acctRecnum,
				amtPaid,
				datePaid,
				receiptNo,
				method,
				checkNo,
				pmtType,
				pmtMode,
				status: status,
				statusReason,
				note,
				description,
				currentIndex,
				dealerID,
				locationID,
				id: null,
				userRecNum: userID,
			};
			setPayment(newPayment);
			// 	clearFields();
			setNewPmt(true);
			setFormTitle("Add Payment");
		}
		//return clearFields;
		// eslint-disable-next-line
	}, []);

	const addPayment = () => {
		const getID = () => {
			if (payments[currentIndex] === undefined) {
				return null;
			} else if (payments[currentIndex].id === undefined) {
				return null;
			} else {
				return payments[currentIndex].id;
			}
		};
		const ID = getID();
		const newPayment = {
			...payment,
			acctRecnum: account.acctRecnum,
			amtPaid: parseFloat(amtPaid),
			datePaid,
			receiptNo,
			method,
			checkNo,
			pmtType,
			pmtMode,
			status: status,
			statusReason,
			note,
			description,
			currentIndex,
			dealerID,
			locationID,
			id: ID,
			userRecNum: userID,
		};
		console.log(newPayment);
		console.log("Current index is: " + currentIndex);

		const paymentList = () => {
			let obj = payments;
			obj[currentIndex] = newPayment;
			return obj;
		};
		const newPayments = paymentList();
		editPayments(newPayments);
		console.log(newPayments);
		save();

		//saveAccount(getAcct());
		//		clearFields();
		toggle();
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	useEffect(() => {
		if (pmtType && PaymentTypeToModeMapping[pmtType]) {
			const modeValues = PaymentTypeToModeMapping[pmtType];
			const filteredModes = PaymentMode.filter((mode) =>
				modeValues.includes(mode.value)
			);
			setFilteredPaymentMode(filteredModes);
		} else {
			setFilteredPaymentMode(PaymentMode);
		}
	}, [pmtType]);

	useEffect(() => {
		const calcBreakdown = () => {
			if (currentIndex < payments.length) return;
			let amountPaid = amtPaid;
			console.log("amountpaid: " + amountPaid);
			console.log("amtPaid:" + amtPaid);
			if (pmtType === PmtType.LOAN) {
				switch (pmtMode) {
					case PmtMode.LATE_FEE: {
						setLateFeePaid(amountPaid);
						break;
					}
					case PmtMode.PRINCIPAL_ONLY: {
						setPrincipalPaid(amountPaid);
						break;
					}
					case PmtMode.INTEREST_ONLY: {
						setInterestPaid(amountPaid);
						break;
					}
					case PmtMode.ADJUSTMENT: {
						setAdjFeePaid(amountPaid);
						break;
					}
					case PmtMode.REGULAR: {
						let lateFeeDue = account?.lateFeeDue || 0;
						if (amountPaid <= lateFeeDue) {
							setLateFeePaid(amountPaid);
							amountPaid = 0;
							break;
						} else {
							setLateFeePaid(lateFeeDue);
							amountPaid = amountPaid - lateFeeDue;
						}
						console.log(amountPaid);
						console.log(account.totalDebitCredit);
						let totalDebitCredit = account.totalDebitCredit || 0;
						if (amountPaid < totalDebitCredit) {
							setAdjFeePaid(amountPaid);
							amountPaid = 0;
							break;
						} else {
							setAdjFeePaid(totalDebitCredit);
							amountPaid = amountPaid - totalDebitCredit;
						}
						console.log(amountPaid);
						let monthlyTax = account.monthlyTax || 0;
						if (amountPaid < monthlyTax) {
							payment.setTaxPaid(amountPaid);
							amountPaid = 0;
						} else {
							setTaxPaid(monthlyTax);
							amountPaid = amountPaid - monthlyTax;
						}
						console.log(amountPaid);
						const days = dayjs(payment.datePaid).diff(
							account.actualLastDatePaid,
							"day"
						);
						console.log("amountpaid: " + amountPaid);
						console.log("amountFinance: " + account.amtFinanced);
						console.log("principle: " + account.principle);
						console.log("dailyIntRate: " + dailyIntRate);
						console.log("days: " + days);
						console.log("amtFinanced: " + account.amtFinanced);
						console.log("principle: " + account.principle);
						let interest =
							days * dailyIntRate * (account.amtFinanced - account.principle);
						if (interest - amountPaid > 0) interest = amountPaid;
						let principle = amountPaid - interest;
						principle = principle < 0 ? 0 : principle;
						console.log(amountPaid);
						setInterestPaid(interest);
						setPrincipalPaid(principle);
						break;
					}
					default:
						break;
				}
			}
			if (pmtType === PmtType.PICKUP) {
				setPickupPaid(amountPaid);
			}
		};
		calcBreakdown();
		// eslint-disable-next-line
	}, [amtPaid]);

	return (
		<>
			{currentIndex === payments.length && (
				<div className="to-be-paid-card">
					<FormProvider className="" {...methods}>
						<Form>
							<InputWrapper
								formTitle="To Be Paid"
								inputComponents={[
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.pastDue || 0.0}
										onChange={(e) => {
											console.log(e);
										}}
										name="loanDue"
										label="Loan Due"
										type="number"
									/>,

									// <CurrencyInput
									// 	disableValidation
									// 	readOnly={true}
									// 	value={account.principleDue || 0.0}
									// 	onChange={(e) => {
									// 		console.log(e);
									// 	}}
									// 	name="principleDue"
									// 	label="Principle Due"
									// 	type="number"
									// />,
									// <CurrencyInput
									// 	disableValidation
									// 	readOnly={true}
									// 	value={account.interestDue || 0.0}
									// 	onChange={(e) => {
									// 		console.log(e);
									// 	}}
									// 	name="interestDue"
									// 	label="Interest Due"
									// 	type="number"
									// />,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.lateFeeDue || 0.0}
										onChange={(e) => {
											console.log(e);
										}}
										name="lateFeeDue"
										label="Late Fee Due"
										type="number"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.totalDebitCredit || 0.0}
										onChange={(e) => {
											console.log(e);
										}}
										name="adjustmentDue"
										label="Adjustment Due"
										type="number"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.pickupPastDue || 0.0}
										onChange={(e) => {
											console.log(e);
										}}
										name="pickupPastDue"
										label="Pickup Past Due"
										type="number"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.regularPayment}
										onChange={(e) => {
											console.log(e);
										}}
										name="regularPayment"
										label="Regular Payment"
										type="number"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.payoffAmount || 0.0}
										onChange={(e) => {
											console.log(e);
										}}
										name="payoffAmount"
										label="Payoff Amount"
										type="number"
									/>,
								]}
							/>
						</Form>
					</FormProvider>
				</div>
			)}
			<FormProvider className="" {...methods}>
				<Form>
					<InputWrapper
						formTitle={formTitle}
						inputComponents={[
							<InputElement
								{...methods}
								readOnly={!newPmt}
								value={datePaid}
								onChange={(e) => {
									setdatePaid(e.target.value);
								}}
								name="datePaid"
								label="Date Paid"
								type="date"
							/>,
							<SelectorElement
								{...methods}
								readOnly={!newPmt}
								name="pmtType"
								label="Payment Type"
								value={pmtType}
								onChange={(e) => setPmtType(e.target.value)}
								options={PaymentType}
							/>,
							<SelectorElement
								{...methods}
								readOnly={!newPmt}
								name="pmtmode"
								label="Payment Mode"
								value={pmtMode}
								onChange={(e) => setPmtMode(e.target.value)}
								options={filteredPaymentMode}
							/>,
							<SelectorElement
								{...methods}
								readOnly={!newPmt}
								name="method"
								label="Methods"
								value={method}
								onChange={(e) => handleMethod(e.target.value)}
								options={paymentMethod}
							/>,

							<CurrencyInput
								disableValidation
								readOnly={!newPmt}
								value={amtPaid}
								onChange={setAmtPaid}
								name="amountPaid"
								label="Amount Paid"
								type="number"
							/>,
							<InputElement
								{...methods}
								readOnly={!newPmt}
								value={receiptNo}
								onChange={(e) => {
									setreceiptNo(e.target.value);
								}}
								name="receiptNo"
								label="Receipt #"
								type="text"
							/>,
							<InputElement
								{...methods}
								readOnly={!newPmt}
								value={checkNo}
								onChange={(e) => {
									setCheckNo(e.target.value);
								}}
								name="checkNo"
								label="Reference No"
								type="text"
							/>,
							<InputElement
								{...methods}
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								name="description"
								label="Description"
								type="text"
							/>,
							<SelectorElement
								{...methods}
								readOnly={!newPmt}
								name="paymentStatus"
								label="Payment Status"
								value={status}
								onChange={(e) => setStatus(e.target.value)}
								options={PaymentStatus}
							/>,
							<InputElement
								{...methods}
								value={statusReason}
								onChange={(e) => {
									setStatusReason(e.target.value);
								}}
								name="statusReason"
								label="Status Reason"
								type="text"
							/>,
							<InputElement
								{...methods}
								value={note}
								onChange={(e) => {
									setNote(e.target.value);
								}}
								name="note"
								label="Note"
								type="text"
							/>,
						]}
					/>
					{merchantId && (
						<>
							<ChildrenModal modal={digitzsModal} toggle={toggleDigitzsModal}>
								<DigitzsPayment pmtAmount={amtPaid} />
							</ChildrenModal>
							<Button onClick={toggleDigitzsModal}>Process Credit Card</Button>
						</>
					)}
				</Form>
			</FormProvider>
			<div className="payment-breakdown-card">
				<FormProvider className="" {...methods}>
					<Form>
						<InputWrapper
							formTitle="Payment Breakdown"
							inputComponents={[
								<CurrencyInput
									disableValidation
									readOnly={true}
									value={lateFeePaid}
									onChange={(e) => {
										console.log(e);
									}}
									name="lateFeePaid"
									label="Late Fee Paid"
									type="number"
								/>,
								<CurrencyInput
									disableValidation
									readOnly={true}
									value={taxPaid}
									onChange={(e) => {
										console.log(e);
									}}
									name="taxPaid"
									label="Tax Paid"
									type="number"
								/>,
								<CurrencyInput
									disableValidation
									readOnly={true}
									value={interestPaid}
									onChange={(e) => {
										console.log(e);
									}}
									name="interestPaid"
									label="Interest Paid"
									type="number"
								/>,
								<CurrencyInput
									disableValidation
									readOnly={true}
									value={principalPaid}
									onChange={(e) => {
										console.log(e);
									}}
									name="principlePaid"
									label="Principle Paid"
									type="number"
								/>,
								<CurrencyInput
									disableValidation
									readOnly={true}
									onChange={(e) => {
										console.log(e);
									}}
									value={adjFeePaid}
									name="adjFeePaid"
									label="Adjustment Paid"
									type="number"
								/>,
								<CurrencyInput
									disableValidation
									readOnly={true}
									onChange={(e) => {
										console.log(e);
									}}
									value={pickupPaid}
									name="pickupPaid"
									label="Pickup Paid"
									type="number"
								/>,
							]}
							buttons={
								<div style={{ display: "flex", justifyContent: "center" }}>
									<Button
										active={account.status === 0}
										color="primary"
										onClick={methods.handleSubmit(addPayment)}
									>
										Save
									</Button>
									<ReceiptButton
										paymentId={payment?.id}
										accountID={account?.ID}
									/>

									{currentIndex === payments.length - 1 &&
										account.status !== "void" && (
											<Button
												active={account.status === 0}
												color="danger "
												onClick={() => {
													setStatus("void");
													methods.handleSubmit(addPayment);
												}}
											>
												Void
											</Button>
										)}
								</div>
							}
						/>
					</Form>
				</FormProvider>
				{!merchantId && (
					<Row className="mt-2 justify-content-center">
						<Col xs="12" sm="12" md="6" lg="6" xl="4" className="mb-3 d-grid ">
							<ListItemModal
								modalListTitle="Digitzs"
								modalButtonTitle="Sign Up"
								modalTitle="Digitzs Sign Up"
								modal={digitzsModal}
								toggle={toggleDigitzsModalApi}
								cardClassName="mb-2"
								imageLogo={DigitzsImg}
								disabled={false}
								integrationInfo="Digitzs is our preferred payment processing solution. Low rates, quick transfers, and fully integrated into your system."
							>
								<Digitzs toggle={toggleDigitzsModal} />
							</ListItemModal>
						</Col>
					</Row>
				)}
			</div>
		</>
	);
};

export default AccountPaymentForm;
