import React from "react";
import { Card, CardBody, CardTitle, Button, CardImg } from "reactstrap";
import AgriSureImage from "assets/img/AgriSure.svg";

/**
 * Component for rendering an AUL sign-up button.
 */
const AgriSureButton = () => {
	/**
	 * Opens a link to the AUL sign-up PDF in a new tab.
	 */
	const commitData = () => {
		window.open(
			"https://portal.agri-sure.com/",
			"_blank",
			"noopener,noreferrer"
		);
	};

	return (
		<Card
			className="text-center mt-4 shadow-sm"
			style={{ maxWidth: "18rem", margin: "auto" }}
		>
			<CardBody>
				<div className="mb-2">
					<CardImg
						top
						width="150%"
						src={AgriSureImage}
						alt="AgriSure Logo"
						style={{ maxWidth: "150px", margin: "auto" }}
					/>
				</div>
				{/* <CardTitle tag="h5" className="mb-3">
					Agri Sure Portal
				</CardTitle> */}
				<Button size="sm" color="primary" onClick={commitData}>
					Sign Up
				</Button>
			</CardBody>
		</Card>
	);
};

export default AgriSureButton;
