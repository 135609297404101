import React, { useContext } from "react";
import { Table, Button, Col, CardTitle } from "reactstrap";
import { Card, CardHeader, CardBody } from "reactstrap";
import useCurrentDealStore from "stores/DealStore";
import NumberFormat from "react-number-format";
import { showApiError } from "utils/errorRoutingUtils";
import {
	deleteSuccessAlert,
	deleteFailAlert,
	confirmDeleteAlert,
} from "utils/alertUtils";
import shallow from "zustand/shallow";
import { deleteWholesaleInvID } from "../../../../../api/DealAPI";
import { WholesaleInvsContext } from "./WholeSaleInvsContext";
import WholeSaleInvsForm from "./WholeSaleInvsForm";
import ChildrenModal from "../../../../../components/modals/ChildrenModal";
import useCurrentSaleStore from "../../../../../stores/SaleStore";

const WholesaleInvsTable = () => {
	const { wholesaleInvs, editWholesaleInvs, reset } = useCurrentDealStore(
		(state) => ({
			wholesaleInvs: state.wholesaleInvs,
			editWholesaleInvs: state.editWholesaleInvs,
		}),
		shallow
	);
	// State from sale store
	const { saleType } = useCurrentSaleStore(
		(state) => ({
			saleType: state.saleType,
		}),
		shallow
	);

	const {
		modalActive,
		toggleWholesaleInvsModal,
		setCurrentIndex,
		isLoading,
		editIsLoading,
	} = useContext(WholesaleInvsContext);

	// Function to handle removing items
	const removeWholesaleInvs = (ID, index) => {
		// If ID is present, make an API call and filter out by ID
		if (ID) {
			deleteWholesaleInvID(ID).then(
				(res) => {
					// Remove item locally after successful deletion
					const updatedList = wholesaleInvs.filter((item) => item.id !== ID);
					editWholesaleInvs(updatedList);
					deleteSuccessAlert();
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		} else {
			// Filter out by index if ID is not available
			const updatedList = wholesaleInvs.filter((_, idx) => idx !== index);
			editWholesaleInvs(updatedList);
		}
		editIsLoading(false); // Ensure loading state is reset
	};

	// Confirm delete modal
	// const toggleDeleteModal = (ID, index) => {
	// 	confirmDeleteAlert(() => removeWholesaleInvs(ID, index), ID, index);
	// };

	const toggleDeleteModal = (ID, index) => {
		console.log(ID, index);
		if (ID) {
			confirmDeleteAlert(() => removeWholesaleInvs(ID, index), ID, index);
		} else {
			removeWholesaleInvs(ID, index);
		}
	};

	// Map over the data to generate table rows
	const tableData = wholesaleInvs.map((obj, index) => (
		<tr key={obj.ID || index}>
			<th scope="row">{index + 1}</th>
			<td>{`${obj?.car?.year || ""} ${obj?.car?.make || ""} ${
				obj?.car?.model || ""
			}`}</td>
			<td>{obj?.car?.stockNo || "N/A"}</td>
			<td>{obj?.car?.vin || "N/A"}</td>
			{saleType === 3 && (
				<td>
					<NumberFormat
						value={obj.salesPrice}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={"$"}
						isNumericString={true}
						displayType="text"
					/>
				</td>
			)}
			<td style={{ textAlign: "right" }}>
				<Button
					color="success"
					onClick={() => {
						setCurrentIndex(index);
						toggleWholesaleInvsModal();
					}}
				>
					Edit
				</Button>{" "}
				<Button color="danger" onClick={() => toggleDeleteModal(obj.id, index)}>
					X
				</Button>
			</td>
		</tr>
	));

	return (
		<>
			{/* Modal for editing */}
			<ChildrenModal
				modal={modalActive}
				toggle={toggleWholesaleInvsModal}
				modalTitle={"Edit Vehicle"}
				onClosed={reset}
			>
				<WholeSaleInvsForm />
			</ChildrenModal>

			{/* Display Table or Message */}
			{wholesaleInvs.length > 0 && !isLoading ? (
				<Card>
					<CardHeader>
						<CardTitle tag="h3">Wholesale Inventories</CardTitle>
					</CardHeader>
					<CardBody>
						<Col style={{ overflowX: "auto" }}>
							<Table striped hover>
								<thead>
									<tr>
										<th>#</th>
										<th>Car</th>
										<th>Stock</th>
										<th>VIN</th>
										{/* <th>Sale Price</th> */}
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>{tableData}</tbody>
							</Table>
						</Col>
					</CardBody>
				</Card>
			) : (
				<h5 className="text-center my-3">
					No vehicles in the wholesale inventory.
				</h5>
			)}
		</>
	);
};

export default WholesaleInvsTable;
