import React from "react";
import { Card, CardBody, CardText, Row, Col, Label } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";

const VehicleBreakdown = () => {
	const { price } = useCurrentSaleStore((state) => state, shallow);
	const { cost, totalOtherCost, totalRecon, flooring, pack, totalCost } =
		useCurrentDealStore(
			(state) => ({
				cost:
					(state.vehicle?.cost || 0) +
					(state.wholesaleInvs?.length > 0
						? state.wholesaleInvs.reduce((acc, inv) => acc + inv.car.cost, 0)
						: 0),
				totalOtherCost:
					(state.vehicle?.totalOtherCost || 0) +
					(state.wholesaleInvs?.length > 0
						? state.wholesaleInvs.reduce(
								(acc, inv) => acc + inv.car.totalOtherCost,
								0
						  )
						: 0),
				totalRecon:
					(state.vehicle?.totalRecon || 0) +
					(state.wholesaleInvs?.length > 0
						? state.wholesaleInvs.reduce(
								(acc, inv) => acc + inv.car.totalRecon,
								0
						  )
						: 0),
				totalCost:
					(state.vehicle?.totalCost || 0) +
					(state.wholesaleInvs?.length > 0
						? state.wholesaleInvs.reduce(
								(acc, inv) => acc + inv.car.totalCost,
								0
						  )
						: 0),
				pack:
					(state.vehicle?.pack || 0) +
					(state.wholesaleInvs?.length > 0
						? state.wholesaleInvs.reduce((acc, inv) => acc + inv.car.pack, 0)
						: 0),
				flooring:
					(state.vehicle?.flooring || 0) +
					(state.wholesaleInvs?.length > 0
						? state.wholesaleInvs.reduce(
								(acc, inv) => acc + inv.car.flooring,
								0
						  )
						: 0),
			}),
			shallow
		);

	const rows = [
		{ label: "Price", amount: price, cost: cost },
		{ label: "Total Recon", amount: 0, cost: totalRecon },
		{ label: "Total Flooring", amount: 0, cost: flooring },
		{ label: "Total Other", amount: 0, cost: totalOtherCost },
		{ label: "Pack", amount: 0, cost: pack },
	];

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Fee</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
						<Col>
							<Label className="h6">Cost</Label>
						</Col>
					</Row>
					<hr />
					<>
						{rows.map((obj) => (
							<Row>
								<Col>
									<Label>{obj.label}</Label>
								</Col>
								<Col>
									<CurrencyDisplayElement val={obj.amount} />
								</Col>
								<Col>
									<CurrencyDisplayElement val={obj.cost} />
								</Col>
							</Row>
						))}
					</>
					<hr />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={price} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalCost} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

export default VehicleBreakdown;
