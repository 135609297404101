/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software ))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import NavBar from "components/tabs/TabsHolder";
import FormsReportsList from "../forms/components/FormsReportsList";
import { useFromLocations, useFromUser } from "stores/LocalStorageHelper";
import { Col } from "reactstrap";
import { TemplateVariableProvider } from "../../context/TemplateVariableContext";
import useIsOwnerOrAdmin from "../settings/subFeatures/settingsTabs/userManagement/useIsOwnerOrAdmin";
import UnauthorizedContent from "../deal/subFeatures/dealTabs/tabsContainer/UnauthorizedContent";

const ReportsPage = () => {
	const locationID = useFromUser("locationID");
	let bhph = 0;
	bhph = useFromLocations("fn");
	const isOwnerOrAdmin = useIsOwnerOrAdmin();
	const tabs = ["Inventory", "Management", "Sales", "Requested"];
	if (bhph === 1) tabs.push("BHPH");

	// if ([4757, 6730, 7942, 5456, 7906, 7464, 7664, 4575].includes(locationID)) {
	// 	bhph = 1;
	// 	tabs.push("BHPH");
	// } else {
	// 	bhph = 0;
	// }

	const tabComponents = [
		<FormsReportsList
			listTitle="Inventory Reports"
			searchable={true}
			listName={"inventoryReports"}
		/>,
		isOwnerOrAdmin ? (
			<FormsReportsList
				listTitle="Management Reports"
				searchable={true}
				listName={"managementReports"}
			/>
		) : (
			<UnauthorizedContent />
		),
		<FormsReportsList
			listTitle="Sales Reports"
			searchable={true}
			listName={"salesReports"}
		/>,
		<FormsReportsList
			listTitle="Requested Reports"
			searchable={true}
			listName={"customReports"}
		/>,
		<>
			{(bhph === 1 && (
				<FormsReportsList
					listTitle="BHPH Reports"
					searchable={true}
					listName={"bhphReports"}
				/>
			)) ||
				null}
		</>,
	];
	return (
		<TemplateVariableProvider>
			{/*No template variable initializer needed */}
			<div className="mx-0 content justify-content-center align-items-center">
				<Col>
					<NavBar
						navClass="nav-pills nav-pills-primary justify-content-center nav"
						tabs={tabs}
						tabComponents={tabComponents}
					/>
				</Col>
			</div>
		</TemplateVariableProvider>
	);
};
export default ReportsPage;
