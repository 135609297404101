import React from "react";
import { Card, Col, Row } from "reactstrap";

import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import AccountCustomerHeader from "./AccountCustomerHeader";

import AccountSummary from "./AccountSummary";
import AccountInventoryHeader from "./AccountInventoryHeader";
import AccountBalance from "./AccountBalance";
import AccountPayoff from "./AccountPayoff";
import Loading from "../components/loadingSpinner/Loading";

export const AccountContent = () => {
	const { buyer, account, isLoading } = useCurrentAccountStore(
		(state) => state,
		shallow
	);

	console.log(buyer);
	console.log(account);
	return (
		<>
			<div style={{ minHeight: "1000px" }}>
				{(isLoading && <Loading containerStyle={{ height: "80vh" }} />) || (
					<Row>
						<Row className="">
							<div className="col-md-4">
								<Col className="text-center px-0">
									<AccountCustomerHeader />
								</Col>
								{account.salesType !== 4 && (
									<Card className="pb-2">
										<AccountInventoryHeader />
									</Card>
								)}
							</div>
							<div className="col-md-5">
								<Card>
									<AccountBalance />
								</Card>
							</div>
							<div className="col-md-3">
								<Card className="py-2">
									<AccountSummary />
								</Card>
							</div>
						</Row>
						<Row className="pt-2">
							<AccountPayoff />
						</Row>
					</Row>
				)}
			</div>
		</>
	);
};
