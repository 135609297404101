import React from "react";
import { Card, CardBody, CardTitle, Button, CardImg } from "reactstrap";
import americasRVImage from "assets/img/americasRV.png";

/**
 * Component for rendering an americasRVWarranty sign-up button.
 */
const AmericasRVButton = () => {
	/**
	 * Opens a link to the americasRVWarranty sign-up PDF in a new tab.
	 */
	const commitData = () => {
		window.open(
			"https://dealerclick.com/americasRVWarranty.pdf",
			"_blank",
			"noopener,noreferrer"
		);
	};

	return (
		<Card
			className="text-center mt-4 shadow-sm"
			style={{ maxWidth: "20rem", margin: "auto" }}
		>
			<CardBody>
				<div className="mb-3">
					<CardImg
						top
						width="100%"
						src={americasRVImage}
						alt="Americas`RV Logo"
						style={{ maxWidth: "100px", margin: "auto" }}
					/>
				</div>
				{/* <CardTitle tag="h5" className="mb-3">
					americasRVWarranty Sign-Up
				</CardTitle> */}
				<Button size="sm" color="primary" onClick={commitData}>
					Sign Up
				</Button>
			</CardBody>
		</Card>
	);
};

export default AmericasRVButton;
