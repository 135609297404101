import React, { createContext, useState, useContext, useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import WholesaleInvsTable from "./WholesaleInvsTable";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import ChildrenModal from "components/modals/ChildrenModal";
import WholeSaleInvsForm from "./WholeSaleInvsForm";
import WholeSaleInvsProvider, {
	WholesaleInvsContext,
} from "./WholeSaleInvsContext";
import useCurrentSaleStore from "../../../../../stores/SaleStore";
import { DealStatus } from "../../../../../constants/Constants";

/**
 * The main component that uses the context
 */
const DealWholesaleInvsList = () => {
	// Retrieve wholesaleInvs from the deal store
	const { wholesaleInvs, type } = useCurrentDealStore(
		(state) => ({
			wholesaleInvs: state.wholesaleInvs,
			type: state.deal.type,
		}),
		shallow
	);
	// Sale store
	const { editPrice } = useCurrentSaleStore(
		(state) => ({
			editPrice: state.editPrice,
		}),
		shallow
	);

	// Retrieve and manage WholesaleInv-related context
	const { modalActive, toggleWholesaleInvsModal, setCurrentIndex, reset } =
		useContext(WholesaleInvsContext);

	useEffect(() => {
		setCurrentIndex(wholesaleInvs.length);
		// eslint-disable-next-line
	}, [wholesaleInvs.length]);
	useEffect(() => {
		console.log(wholesaleInvs);
		let salesPrice = 0;
		wholesaleInvs.forEach((inv) => {
			salesPrice += inv.salesPrice;
		});
		editPrice(salesPrice);
		// eslint-disable-next-line
	}, [wholesaleInvs]);
	/**
	 * Handles the addition of a new WholesaleInv by setting the current index and toggling the modal.
	 */
	const add = () => {
		setCurrentIndex(wholesaleInvs.length);
		toggleWholesaleInvsModal();
	};

	return (
		<>
			<ChildrenModal
				modal={modalActive}
				toggle={toggleWholesaleInvsModal}
				modalTitle={"Add vehicle"}
				onClosed={reset}
			>
				<WholeSaleInvsForm />
			</ChildrenModal>
			<WholesaleInvsTable />
			{type === DealStatus.PENDING && (
				<Row>
					<Col>
						<Button className="mb-2 btn btn-primary btn-sm" onClick={add}>
							<i className="nc-icon nc-simple-add"></i> Add Vehicle
						</Button>
					</Col>
				</Row>
			)}
		</>
	);
};

/**
 * The component that includes the provider wrapper
 */
const DealWholesaleInvsListWithProvider = () => {
	return (
		<WholeSaleInvsProvider>
			<DealWholesaleInvsList />
		</WholeSaleInvsProvider>
	);
};

export default DealWholesaleInvsListWithProvider;
