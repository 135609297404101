import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Button } from "reactstrap";

import RecapSummaryCard from "./RecapSummaryCard";
import RecapMainCard from "./RecapMainCard";
import RecapFinanceCard from "./RecapFinanceCard";
import useSetDeal from "features/deal/hooks/useSetDeal";
import { saveDeal } from "api/DealAPI";
import { saveFailAlert, saveSuccessAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import useBuildDeal from "features/deal/hooks/useBuildDeal";
import { formLoading } from "api/FormsAPI";
import { getPdf } from "api/FormsAPI";
import { createFileAndDownload, displayPdf } from "api/FormsAPI";
import Swal from "sweetalert2";
import { displayError } from "api/FormsAPI";
import useIsOwnerOrAdmin from "../../../settings/subFeatures/settingsTabs/userManagement/useIsOwnerOrAdmin";
import UnauthorizedContent from "../dealTabs/tabsContainer/UnauthorizedContent";

// TODO: Import the already made swal alerts instead of making new ones
const Recap = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();
	const isOwnerOrAdmin = useIsOwnerOrAdmin();

	const handlePrint = () => {
		const deal = getDeal();
		const dealID = deal.deal.ID;
		const body = {
			dealID: dealID,
			inventoryID: null,
			// 205 is ID for deal recap in dcReport
			dcReportID: 205,
			params: { dealerID: dealerID, locationID: locationID },
		};
		formLoading("PDF");
		saveDeal(deal).then(
			(res) => {
				setDeal(res.data.content);
				saveSuccessAlert();
				body.dealID = res.data.content.deal.ID;
				getPdf(body).then(
					(response) => {
						const fileName = "ST_DEAL_RECAP_" + dealID + ".pdf";
						createFileAndDownload(response, fileName);
						displayPdf(response);
						Swal.close();
					},
					(err) => {
						displayError(err);
					}
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	if (!isOwnerOrAdmin) {
		return <UnauthorizedContent />;
	} else {
		return (
			<Row className="justify-content-center">
				<Col md={7} sm={6}>
					<RecapMainCard />
					<div key="spacer" className="noDesktop pb-3 w-100"></div>,
					<RecapFinanceCard />
				</Col>
				<Col md={5} sm={6}>
					<RecapSummaryCard />
					<Button
						onClick={() => {
							handlePrint();
						}}
					>
						Print
					</Button>
				</Col>
			</Row>
		);
	}
};

export default Recap;
