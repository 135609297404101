import React, { useEffect, useState } from "react";
import { CardBody } from "reactstrap";

import useCalculateSales from "features/deal/hooks/useCalculateSales";
import ChildrenModal from "components/modals/ChildrenModal";
import CommissionTable from "features/deal/subFeatures/dealTabs/commissionsTab/CommissionTable";
import ServiceContract from "features/deal/subFeatures/sale/saleComponents/ServiceContract";
import ContractInfoDetail from "features/deal/subFeatures/dealDetails/ContractInfoDetail";
import OpenTotalModal from "features/deal/subFeatures/sale/OpenTotalModal";
import Aftermarket from "features/deal/subFeatures/sale/saleComponents/Aftermarket";
import DMVFee from "features/deal/subFeatures/sale/saleComponents/DMVFee";
import InsuranceAndGAP from "features/deal/subFeatures/sale/saleComponents/InsuranceAndGAP";
import SellerFees from "features/deal/subFeatures/sale/saleComponents/SellerFees";
import useCurrentSaleStore from "../../../../../stores/SaleStore";
import shallow from "zustand/shallow";

const TemplateSale = ({ activeTab }) => {
	const { saleType } = useCurrentSaleStore((state) => shallow);

	console.log(saleType);
	const [openCommissions, setOpenCommissions] = useState(false);
	console.log(activeTab);
	useCalculateSales();

	// Toggles commission modal
	const toggleCommissions = () => setOpenCommissions(!openCommissions);
	useEffect(() => {
		console.log(saleType);
		// eslint-disable-next-line
	}, [saleType]);

	return (
		<CardBody>
			{activeTab === "1" && saleType !== "3" && <DMVFee />}
			{activeTab === "1" && <SellerFees />}
			{saleType !== "3" && <InsuranceAndGAP />}
			<ServiceContract />
			<Aftermarket />
			{activeTab === "1" && <ContractInfoDetail />}
			{activeTab === "1" && (
				<OpenTotalModal
					btnClass="w-100 btn btn-info btn-md px-0 col-6"
					label="Commissions"
					toggle={toggleCommissions}
				/>
			)}
			{activeTab === "1" && (
				<ChildrenModal
					modal={openCommissions}
					toggle={toggleCommissions}
					size="xl"
					modalTitle="Commissions"
				>
					<CommissionTable />
				</ChildrenModal>
			)}
		</CardBody>
	);
};

export default TemplateSale;
