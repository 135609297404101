import React from "react";
import { CardHeader, CardTitle, Col, Form, Label, Row } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import LabelAndInput from "./LabelAndInput";
import SellerFees from "./saleComponents/SellerFees";
import Aftermarket from "./saleComponents/Aftermarket";
import { DealStatus } from "constants/Constants";
import useCurrentDealStore from "stores/DealStore";
import formNavigationUtils from "utils/formNavigationUtils";
import shallow from "zustand/shallow";
import ContractInfo from "../dealDetails/ContractInfoDetail";
import { CurrencyDisplayElement } from "../../components/DisplayElement";
import NumberFormat from "react-number-format";
import DownPayment from "./saleComponents/DownPayment";

/**
 * Sale component displays the sale details and inputs for a deal.
 *
 * @returns {JSX.Element} The rendered component
 */
const BulkSale = () => {
	const {
		price,
		editPrice,
		onePay,
		manufacturerRebate,
		editManufacturerRebate,
	} = useCurrentSaleStore(
		(state) => ({
			price: state.price,
			editPrice: state.editPrice,
			onePay: state.onePay,
		}),
		shallow
	);

	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	return (
		<div>
			<CardHeader>
				<CardTitle tag="h3">{type} Deal</CardTitle>
			</CardHeader>
			<div className="card-body pb-3" onKeyDown={formNavigationUtils}>
				<Form className="deal-sale">
					<Row className="align-items-center sale-container">
						<div className="sale-item">
							<LabelAndInput
								name="dealPrice"
								disabled={type !== DealStatus.PENDING}
								value={price}
								label="Price"
								onChange={editPrice}
								btnClass="w-100 btn-md btn-primary btn"
								buttonLabel="true"
							/>
						</div>
						{/* <div className="sale-item">
							<LabelAndInput
								name="dealMFR"
								className="text-danger bold"
								disabled={type !== DealStatus.PENDING}
								buttonLabel={true}
								value={manufacturerRebate}
								label="MFR Rebate"
								onChange={editManufacturerRebate}
								sign="-"
								btnClass="w-100 btn-md btn-primary btn px-0"
							/>
						</div> */}

						<hr className="mb-3 w-75 mx-auto noDesktop" />
						<div className="sale-item">
							<SellerFees />
						</div>

						<div className="sale-item">
							<Aftermarket />
						</div>
						{/* <div className="sale-item">
							<DownPayment />
						</div> */}
					</Row>
					<div className="d-flex align-items-center justify-content-center">
						<span style={{ fontSize: "1.4em" }}>Total Invoice:&nbsp;</span>
						<NumberFormat
							className="text-danger h4"
							value={onePay}
							thousandSeparator={true}
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={"$"}
							isNumericString={true}
							displayType="text"
							readOnly
						/>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default BulkSale;
