import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Card, CardTitle, CardBody, CardHeader, Table } from "reactstrap";
import ReviewRow from "./ReviewRow";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";

/**
 * ReviewVehicle component to display vehicle details.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @returns {JSX.Element} The rendered ReviewVehicle component
 */
const ReviewVehicle = () => {
	const {
		stockNo,
		year,
		make,
		model,
		vin,
		license,
		odometer,
		numCylin,
		wholesaleInvs,
	} = useCurrentDealStore(
		(state) => ({
			stockNo: state?.vehicle?.stockNo || "",
			year: state?.vehicle?.year || "",
			make: state?.vehicle?.make || "",
			model: state?.vehicle?.model || "",
			vin: state?.vehicle?.vin || "",
			license: state?.vehicle?.license || "",
			odometer: state?.vehicle?.odometerOut || "",
			numCylin: state?.vehicle?.numCylinders || "",
			wholesaleInvs: state?.wholesaleInvs || [],
		}),
		shallow
	);

	// Log for debugging
	console.log("wholesaleInvs:", wholesaleInvs);

	return (
		<Card>
			<CardHeader>
				<CardTitle tag="h3">Vehicle</CardTitle>
			</CardHeader>
			<CardBody>
				{/* Render wholesale inventory if available */}
				{wholesaleInvs.length > 0 ? (
					<div style={{ overflowX: "auto" }}>
						<Table striped>
							<thead>
								<tr>
									<th>#</th>
									<th>Stock No</th>
									<th>Vehicle</th>
									<th>Model</th>
									<th>VIN</th>
									<th>License</th>
									<th>Odometer</th>
									<th># of Cylinders</th>
								</tr>
							</thead>
							<tbody>
								{wholesaleInvs.map((element, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										<td>{element.car.stockNo}</td>
										<td>{`${element.car.year} ${element.car.make}`}</td>
										<td>{element.car.model}</td>
										<td>{element.car.vin}</td>
										<td>{element.car.license || "N/A"}</td>
										<td>{element.car.odometer || "N/A"}</td>
										<td>{element.car.numCylin || "N/A"}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</div>
				) : (
					// Render default vehicle details if no wholesale inventory
					<>
						<ReviewRow label="Stock No" value={stockNo} />
						<ReviewRow label="Vehicle" colSize="4" value={`${year} ${make}`} />
						<ReviewRow label="" value={model} />
						<ReviewRow label="VIN" colSize="3" value={vin} />
						{license && <ReviewRow label="License" value={license} />}
						{odometer && <ReviewRow label="Odometer" value={odometer} />}
						{numCylin && <ReviewRow label="# of Cylinders" value={numCylin} />}
					</>
				)}
			</CardBody>
		</Card>
	);
};

ReviewVehicle.propTypes = {
	stockNo: PropTypes.string,
	year: PropTypes.string,
	make: PropTypes.string,
	model: PropTypes.string,
	vin: PropTypes.string,
	license: PropTypes.string,
	odometer: PropTypes.string,
	numCylin: PropTypes.string,
};

export default ReviewVehicle;
