import React from "react";
import DigitzsPayment from "./DigitzsPayment";

const PaymentTerminalPage = () => {
	return (
		<div className="content">
			<DigitzsPayment pmtAmount={1} />
		</div>
	);
};

export default PaymentTerminalPage;
