import React, { useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Col, Input, Row, Form, Label } from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";
import useCurrentRecapStore from "stores/RecapStore";
import NumberFormat from "react-number-format";
import {
	CurrencyInput,
	PercentInput,
} from "features/forms/components/InputElement";

/**
 * LenderFeeForm component to display and manage lender fees.
 *
 * @component
 * @returns {JSX.Element} The rendered component
 */
export const LenderFeeForm = () => {
	const { amtFinanced, fiDiscount, discount, editFiDiscount, editDiscount } =
		useCurrentSaleStore((state) => state, shallow);

	const {
		lenderFee,
		editLenderFee,
		lenderPaid,
		editLenderPaid,
		netCheckToDealer,
		totalLenderFee,
		interestNetProfit,
	} = useCurrentRecapStore((state) => state, shallow);

	const { lender } = useCurrentDealStore((state) => state, shallow);

	useEffect(() => {
		if (lenderPaid === null) {
			const today = dayjs().format("YYYY-MM-DD");
			editLenderPaid(today);
		}
		if (lenderPaid === "1899-12-31") {
			editLenderPaid("");
		}
	}, [lenderPaid, editLenderPaid]);

	const handleBlur = (e) => {
		const netCheckValue = parseFloat(
			e.target.value.replace("$", "").replace(",", "")
		);
		const discountRate =
			1 - (netCheckValue + lenderFee - interestNetProfit) / amtFinanced;
		editDiscount(discountRate);
	};

	return (
		<Form>
			<Row>
				<Col>
					<Label className="h6">{lender?.name || "No Lender"}</Label>
				</Col>
			</Row>
			<Row>
				<Col md="6">
					<label>Lender Discount Rate (%)</label>

					<PercentInput
						value={discount}
						decimalScale={4}
						onChange={editDiscount}
						disableValidation
						wholeRow
					/>
				</Col>
				<Col md="6">
					<label>Amount Financed</label>

					<CurrencyInput
						value={amtFinanced}
						readOnly
						disableValidation
						wholeRow
					/>
				</Col>
				<Col md="6">
					<label>Lender Discount</label>

					<CurrencyInput
						value={fiDiscount}
						//						onChange={editFiDiscount}
						disableValidation
						wholeRow
						onBlur={(e) => {
							console.log(e.target.value);
							if (e.target.value === "") {
								editDiscount(0);
							} else {
								//(discount/amtFinanced)*100=
								let discountRate =
									parseFloat(e.target.value.replace("$", "").replace(",", "")) /
									amtFinanced;
								console.log(discountRate);
								editDiscount(discountRate);
							}
						}}
					/>
				</Col>
				<Col md="6">
					<label>Lender Fee</label>

					<CurrencyInput
						value={lenderFee}
						onChange={editLenderFee}
						disableValidation
						wholeRow
						allowNegative={false}
					/>
				</Col>
				<Col md="6">
					<label>Total Lender Fee</label>

					<CurrencyInput
						value={totalLenderFee}
						readOnly
						disableValidation
						wholeRow
					/>
				</Col>
				<Col md="6">
					<label>Net Finance Profit</label>

					<CurrencyInput
						value={interestNetProfit}
						readOnly
						disableValidation
						wholeRow
					/>
				</Col>
				<Col md="6">
					<label>Net Check To Dealer</label>

					<Col md="6">
						<NumberFormat
							name="netCheckToDealer"
							decimalScale={2}
							thousandSeparator={true}
							prefix={"$"}
							fixedDecimalScale={true}
							isNumericString={true}
							customInput={Input}
							onFocus={(e) => e.target.select()}
							value={netCheckToDealer}
							onBlur={handleBlur}
						/>
					</Col>
				</Col>
				<Col md="6">
					<label>Check Received</label>
					<Input
						type="date"
						name="lenderPaid"
						value={lenderPaid}
						onChange={(e) => editLenderPaid(e.target.value)}
					/>
				</Col>
			</Row>
		</Form>
	);
};

LenderFeeForm.propTypes = {
	amtFinanced: PropTypes.number.isRequired,
	fiDiscount: PropTypes.number,
	discount: PropTypes.number,
	editDiscount: PropTypes.func.isRequired,
	lenderFee: PropTypes.number,
	editLenderFee: PropTypes.func.isRequired,
	lenderPaid: PropTypes.string,
	editLenderPaid: PropTypes.func.isRequired,
	netCheckToDealer: PropTypes.number,
	totalLenderFee: PropTypes.number,
	interestNetProfit: PropTypes.number,
	lender: PropTypes.shape({
		name: PropTypes.string,
	}),
};

export default LenderFeeForm;
