import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";
import Select from "react-select";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import { personnelType } from "constants/Constants";

const DealSalesmanDropDown = ({
	label = "Sales Person",
	isComm = false,
	setPersonRecNum,
	personRecNum,
}) => {
	// Deal store
	const { salesmanRecNum, editSalesmanRecNum, salePeople } =
		useCurrentDealStore(
			(state) => ({
				salesmanRecNum: state.deal.salesmanRecNum,
				editSalesmanRecNum: state.editSalesmanRecNum,
				salePeople: state.salesPeople,
			}),
			shallow
		);
	console.log(isComm ? personRecNum : salesmanRecNum);

	const salespeopleList = salePeople
		.filter(
			(obj) =>
				!obj.isHidden || obj.ID === (isComm ? personRecNum : salesmanRecNum)
		)
		.map((obj) => ({
			label: `${obj.firstName} ${obj.lastName} - ${
				personnelType.find((pt) => pt.value === obj.personnelType)?.label
			}`,
			value: obj.ID,
			dcLogin: obj,
		}));

	// Add "None" to salePeople List if not isComm
	if (!isComm) {
		salespeopleList.push({ label: "None", value: null });
	}

	useEffect(() => {
		if (isComm && personRecNum === null && salespeopleList.length > 0) {
			setPersonRecNum(salespeopleList[0]?.value);
		}
	}, [isComm, personRecNum, salespeopleList, setPersonRecNum]);

	const selectedOption = salespeopleList.find(
		(element) => element.value === (isComm ? personRecNum : salesmanRecNum)
	);

	return (
		<div>
			<Label>{label}</Label>
			<Select
				className="mb-3 selector"
				noOptionsMessage={() => "No person available"}
				value={selectedOption || { label: "None", value: null }}
				options={salespeopleList}
				onChange={(e) => {
					if (isComm) setPersonRecNum(e.value);
					else editSalesmanRecNum(e.value);
				}}
			/>
		</div>
	);
};

DealSalesmanDropDown.propTypes = {
	label: PropTypes.string,
	isComm: PropTypes.bool,
	setPersonRecNum: PropTypes.func.isRequired,
	personRecNum: PropTypes.number,
};

export default DealSalesmanDropDown;
