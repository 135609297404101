import React, { useState, useEffect } from "react";
import {
	Col,
	Table,
	Button,
	Card,
	CardHeader,
	CardTitle,
	CardBody,
} from "reactstrap";
import Switch from "react-bootstrap-switch";
import useBuildLoginInfo from "../../../../hooks/useBuildLoginInfo";

import {
	confirmDeleteMessage,
	saveSuccessAlert,
	failAlert,
} from "../../../../../../utils/alertUtils";
import {
	deactivateDcLogin,
	activateDcLogin,
	getDealersUsers,
	saveUserInfo,
} from "../../../../../../api/LoginAPI";
import { showApiError } from "../../../../../../utils/errorRoutingUtils";
import { useCompanyEmployeeData } from "./CompanyEmployeesContext";
import useCurrentLoginStore from "../../../../../../stores/LoginStore";
import shallow from "zustand/shallow";
import { personnelType } from "../../../../../../constants/Constants";

const CompanyEmployeesTable = ({ toggle, setAddUser }) => {
	const buildLoginInfo = useBuildLoginInfo();

	// Employee context
	const { users, setUsers, setEditUserFields } = useCompanyEmployeeData();
	const { cloudUserCount } = useCurrentLoginStore(
		(state) => ({
			cloudUserCount: state.locationCloudUserCount,
		}),
		shallow
	);
	const [activeUserCount, setActiveUserCount] = useState(0);
	// Headers
	const headers = [
		"#",
		"Name",
		"Type",
		"Title",
		"Role",
		"Email",
		"isHidden",
		"Status",
		"Actions",
	];

	// Modal to ask confirmation to delete an employee
	const toggleDelete = (id) => {
		let confirmDelete = "Deactivate Account";

		confirmDeleteMessage(confirmDelete).then((res) => {
			if (confirmDelete === res) {
				deactivateDcLogin(id).then(
					(res) => {
						let updatedUsers = [...users];
						updatedUsers.forEach((obj) => {
							if (obj.ID === id) {
								obj.active = false;
							}
						});
						setUsers(updatedUsers);
						setActiveUserCount((prevState) => {
							return prevState - 1;
						});
						saveSuccessAlert(res.data.message);
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, failAlert);
						}
					}
				);
			}
		});
	};

	const activate = (id) => {
		activateDcLogin(id).then(
			(res) => {
				let updatedUsers = [...users];
				updatedUsers.forEach((obj) => {
					if (obj.ID === id) {
						obj.active = true;
					}
				});
				setUsers(updatedUsers);
				setActiveUserCount((prevState) => {
					return prevState + 1;
				});
				saveSuccessAlert(res.data.message);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	const handleEmployeeIsHidden = (obj) => {
		obj.isHidden = !obj.isHidden;
		saveUserInfo(obj);
		setEditUserFields(obj);
	};

	const handleEmployeeStatus = (active, dcLoginId) => {
		if (active) {
			toggleDelete(dcLoginId);
		} else {
			activate(dcLoginId);
		}
	};

	// Sets selected employee account information to form and opens it
	const editUser = (user) => {
		setAddUser(false);
		setEditUserFields(user);
		toggle();
	};

	// Gets a list of employee accounts if an admin/owner is logged in
	// Excludes the owner and logged in account from the list (in backend)
	useEffect(() => {
		const failMessage = "Failed to load employee accounts";
		const loginInfo = buildLoginInfo();
		try {
			const privilege = JSON.parse(loginInfo.dcLogin.userPrivileges);

			if (privilege.role === "admin" || privilege.role === "owner") {
				const { ID, dealerID, locationID } = loginInfo.dcLogin;

				getDealersUsers(dealerID, locationID, ID).then(
					(res) => {
						console.log(res);
						setUsers(res.data.content);
						const active = res.data.content.filter((obj) => obj.active);
						// 1 additional because owner is not in the list
						setActiveUserCount(active.length + 1);
					},
					(e) => {
						failAlert(failMessage);
						console.log(e);
					}
				);
			}
		} catch (e) {
			console.log(e);
			failAlert(failMessage);
		}
		// eslint-disable-next-line
	}, []);

	const getRowMap = (arr) => {
		console.log(arr);
		return arr?.map((obj, index) => {
			let role;

			try {
				const privilege = JSON.parse(obj.userPrivileges) || "";

				role =
					privilege.role.charAt(0).toUpperCase() +
						privilege.role.substring(1) || "";
			} catch (e) {
				role = "";
			}

			return (
				<tr key={obj.ID}>
					<th scope="row">{index + 1}</th>
					<td>{`${obj.firstName} ${
						obj.middleName == null || obj.middleName === ""
							? ""
							: `${obj.middleName} `
					}${obj.lastName}`}</td>
					<td>
						{
							(
								personnelType.find((p) => p.value === obj.personnelType) || {
									label: "Unknown",
								}
							).label
						}
					</td>
					<td>{obj.title}</td>
					<td>{role}</td>
					<td>{obj.email}</td>
					<td>
						<Switch
							value={obj.isHidden}
							onChange={() => handleEmployeeIsHidden(obj)}
						/>
					</td>
					<td>
						<Switch
							value={obj.active}
							onChange={() => handleEmployeeStatus(obj.active, obj.ID)}
						/>
					</td>
					<td className="text-left">
						<Button
							className="btn-md"
							color="primary"
							onClick={() => editUser(obj)}
						>
							<i className="nc-icon nc-align-center" /> Edit
						</Button>
					</td>
				</tr>
			);
		});
	};

	// Table headers
	const head = headers.map((key) => <th key={key}>{key}</th>);

	return (
		<Card>
			<CardHeader>
				<CardTitle>
					<h3>
						[{activeUserCount}/{cloudUserCount}] Active Employees Including
						Owner
					</h3>
				</CardTitle>
			</CardHeader>
			<CardBody>
				<Col>
					<Table striped hover>
						<thead>
							<tr>{head}</tr>
						</thead>
						<tbody>{getRowMap(users)}</tbody>
					</Table>
				</Col>
			</CardBody>
		</Card>
	);
};
export default CompanyEmployeesTable;
