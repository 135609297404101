import React, { useState, createContext } from "react";

export const WholesaleInvsContext = createContext();

const WholeSaleInvsProvider = ({ children }) => {
	const [inventoryID, editInventoryID] = useState(null);
	const [bulkVehicle, editBulkVehicle] = useState({});
	const [dealId, editdealId] = useState();
	const [salesPrice, editSalesPrice] = useState();
	const [modalActive, setModalActive] = useState(false);
	const toggleWholesaleInvsModal = () => setModalActive(!modalActive);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isLoading, editIsLoading] = useState(false);

	const context = {
		inventoryID,
		editInventoryID,
		bulkVehicle,
		editBulkVehicle,
		dealId,
		editdealId,
		salesPrice,
		editSalesPrice,
		modalActive,
		setModalActive,
		toggleWholesaleInvsModal,
		currentIndex,
		setCurrentIndex,
		isLoading,
		editIsLoading,
	};
	return (
		<WholesaleInvsContext.Provider value={context}>
			{children}
		</WholesaleInvsContext.Provider>
	);
};

export default WholeSaleInvsProvider;
// const WholesaleInvsProvider = ({ children }) => {
// 	const [modalActive, setModalActive] = useState(false);
// 	const [currentIndex, setCurrentIndex] = useState(0);

// 	const toggleWholesaleInvsModal = () => {
// 		setModalActive((prev) => !prev);
// 	};

// 	const reset = () => {
// 		setCurrentIndex(0);
// 		// Reset other states if necessary
// 	};

// 	return (
// 		<WholesaleInvsContext.Provider
// 			value={{
// 				modalActive,
// 				toggleWholesaleInvsModal,
// 				setCurrentIndex,
// 				reset,
// 			}}
// 		>
// 			{children}
// 		</WholesaleInvsContext.Provider>
// 	);
// };
