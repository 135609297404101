import React, { useState } from "react";
import { Col, Row, Button, CardText } from "reactstrap";

import { saveFailAlert } from "../utils/alertUtils";
import ChildrenModal from "../components/modals/ChildrenModal";
import shallow from "zustand/shallow";
import { useFromUser } from "../stores/LocalStorageHelper";
import {
	//createFileAndDownload,
	displayError,
	displayPdf,
	formLoading,
	getPdf,
} from "../api/FormsAPI";
import { showApiError } from "../utils/errorRoutingUtils";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import useCurrentAccountStore from "./AccountStore";
import AccountPaymentForm from "./AccountPaymentForm";
import { saveAccount } from "./AccountAPI";
import useBuildAccount from "./useBuildAccount";
import useSetAccount from "./useSetAccount";
import {
	CurrencyDisplayElement,
	DateDisplayElement,
} from "../features/deal/components/DisplayElement";
import ReactTable from "../components/tables/ReactTable";
import { PaymentMode } from "../constants/Constants";
import FilterColumn from "../utils/filterColumn";

const AccountPaymentsTable = () => {
	const { account, payments } = useCurrentAccountStore(
		(state) => ({
			payments: state.payments,
			editPayments: state.editPayments,
			account: state.account,
		}),
		shallow
	);

	const [currentIndex, setCurrentIndex] = useState(payments?.length);
	const [showAdd, setShowAdd] = useState(false);
	const toggle = () => setShowAdd(!showAdd);

	const columns = [
		{
			Header: "#",
			accessor: (row, index) => index + 1,
			style: { width: "20px" },
		},
		{
			Header: "PMT_Date",
			accessor: "datePaid",
			Cell: ({ value }) => <DateDisplayElement val={value} />,
		},

		{
			Header: "PMT_Type",
			accessor: "pmtType",
			Filter: FilterColumn,
			filter: "includes",
		},
		{
			Header: "PMT_Mode",
			accessor: "pmtMode",
			Cell: ({ value }) => {
				const foundItem = PaymentMode.find((item) => item.value === value);
				return foundItem ? foundItem.label : "Not Found";
			},
		},
		{
			Header: "Receipt#",
			accessor: "receiptNo",
		},
		{
			Header: "Amount",
			accessor: "amtPaid",
			Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
		},
		{
			Header: "Method",
			accessor: "method",
		},
		{
			Header: "Interest",
			accessor: "interestPaid",
			Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
		},
		{
			Header: "Principal",
			accessor: "principalPaid",
			Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
		},
		{
			Header: "TaxPaid",
			accessor: "taxPaid",
			Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
		},
		{
			Header: "LatePaid",
			accessor: "lateFeepaid",
			Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
		},
		{
			Header: "AdjPaid",
			accessor: "adjFeePaid",
			Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
		},
		{
			Header: "PrincBal",
			accessor: "balance",
			Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
		},
		{
			Header: "LoanBal",
			accessor: "loanBalance",
			Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
		},
		{
			Header: "Status",
			accessor: "status",
			Filter: FilterColumn,
			filter: "includes",

			Cell: ({ value }) => (value === "0" ? "completed" : value),
		},
		{
			Header: "Actions",
			accessor: (row, index) => ({ ...row, index }), // Include the entire row and index
			disableFilters: true,
			Cell: ({ value }) => (
				<div className="d-flex justify-content-center">
					{account.status === 0 && (
						<div
							className="btn btn-md btn-primary"
							onClick={() => {
								setCurrentIndex(value.index);
								toggle();
							}}
						>
							<i className="nc-icon nc-align-center"></i>
							<span className="noMobile">Edit</span>
						</div>
					)}
				</div>
			),
		},
	];

	return (
		<>
			<ChildrenModal
				size="lg"
				modal={showAdd}
				toggle={toggle}
				modalTitle="Payment"
			>
				<AccountPaymentForm
					currentIndex={currentIndex}
					setCurrentIndex={setCurrentIndex}
					toggle={toggle}
				/>
			</ChildrenModal>

			<Card>
				<CardBody>
					<div>
						<ReactTable data={payments} columns={columns} />
					</div>
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<Row>
						<Col sm="6">
							<CardText tag="h3" className="text-center">
								Payments
							</CardText>
						</Col>
						<Col sm="6">
							<CardText tag="h3" className="text-center">
								<NumberFormat
									value={
										account.principle +
										account.interest +
										account.adjPaid +
										account.latePaid +
										account.pickupPaid
									}
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={"$"}
									isNumericString={true}
									readOnly={true}
									displayType="text"
								/>
							</CardText>
						</Col>
					</Row>
				</CardBody>
			</Card>

			<Row>
				<Col>
					{account.status === 0 && (
						<Button
							className="btn btn-primary btn-md"
							onClick={() => {
								setCurrentIndex(payments.length);
								toggle();
							}}
						>
							<i className="nc-icon nc-simple-add" />
							Add
						</Button>
					)}
				</Col>
				<Col></Col>
			</Row>
		</>
	);
};
export default AccountPaymentsTable;
