import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl } from "../constants/Constants";
// import { LOCAL } from "./Constants";

// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL + "/liability/",
// });
const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/customer/liability/",
	//baseURL: LOCAL + "/liability/",
});
// Gets a customer's list of liabilities
export const getCustomerLiabilities = async (custRecNum) => {
	return await instance.get(`customer/${custRecNum}`);
};
//Gets a lead's list of liabilities

export const getLeadLiabilities = async (leadID) => {
	return await instance.get(`lead/${leadID}`);
};

//Gets a creditapp's list of liabilities

export const getCreditAppLiabilities = async (creditAppID) => {
	return await instance.get(`creditapp/${creditAppID}`);
};

// Saves a  liability
export const saveLiability = async (body) => {
	return await instance.post(`save`, body);
};

// Deletes a liability
export const deleteLiability = async (id) => {
	return await instance.post(`delete/${id}`, null);
};
