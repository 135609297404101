import React from "react";
import { Col, Table, Button, Card, CardTitle, CardBody } from "reactstrap";

import useCurrentCustomerStore from "stores/CustomerStore";

import { showApiError } from "../../../../../utils/errorRoutingUtils";
import {
	deleteSuccessAlert,
	deleteFailAlert,
	confirmDeleteAlert,
} from "../../../../../utils/alertUtils";

import shallow from "zustand/shallow";
import { deleteLiability } from "../../../../../api/LiabilitiesApi";
import { CurrencyDisplayElement } from "../../../../deal/components/DisplayElement";

/**
 * @description This table generates a list of addresses
 * @param setCurrentIndex Used to index the store address history array
 */
const LiabilityTable = ({
	disabled,
	setCurrentIndex,
	toggle,
	liabilities,
	editLiabilities,
}) => {
	// Headers
	const headers = [
		"#",
		"Type",
		"Balance",
		"Mountly Pmt",
		"Est. Apr",
		"#Paid Pmts ",
		"#Remaining Pmts ",
		"FICO",
		"Actions",
	];

	// // Customer store
	// const { liabilities, editLiabilities } = useCurrentCustomerStore(
	// 	(state) => ({
	// 		liabilities: state.liabilities,
	// 		editLiabilities: state.editLiabilities,
	// 	}),
	// 	shallow
	// );

	// Modal to ask confirmation to delete an address
	const toggleDelete = (currentID) => {
		confirmDeleteAlert(removeliability, currentID);
	};

	// Calls the delete address API
	const removeliability = (ID) => {
		console.log(ID);
		if (ID != null) {
			deleteLiability(ID).then(
				() => {
					let obj = liabilities.filter((item) => item.id !== ID);
					editLiabilities(obj);
					setCurrentIndex(obj.length);
					deleteSuccessAlert();
				},
				(err) => {
					console.log(err);

					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
	};

	// Address table headers
	const head = headers.map((key) => <th key={key}>{key}</th>);

	// Address row items
	const rows = liabilities.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td>{key.collateralType}</td>
			<td>{<CurrencyDisplayElement val={key.loanBalance} />}</td>
			<td>{<CurrencyDisplayElement val={key.payment} />}</td>
			<td>{key.estAPR}</td>
			<td>{key.pmtsMades}</td>
			<td>{key.pmtsRemain}</td>
			<td>{key.fico}</td>

			<td className="text-right">
				<Button
					disabled={disabled}
					className="btn-sm"
					color="success"
					onClick={() => {
						setCurrentIndex(index);
						toggle();
					}}
				>
					Edit
				</Button>
				<Button
					disabled={disabled}
					className="btn-sm"
					color="danger"
					onClick={() => {
						toggleDelete(key.id);
					}}
				>
					X
				</Button>
			</td>
		</tr>
	));

	return (
		<>
			{rows && rows.length > 0 && (
				<Card>
					{/* <CardTitle>
						<h3>Liabilities</h3>
					</CardTitle> */}
					<CardBody>
						<Col style={{ overflowX: "auto" }}>
							<Table striped hover>
								<thead>
									<tr>{head}</tr>
								</thead>
								<tbody>{rows}</tbody>
							</Table>
						</Col>
					</CardBody>
				</Card>
			)}

			{rows && rows.length === 0 && (
				<div className="text-center">
					<CardBody>
						<Col>
							<h3>No Auto Loans </h3>
						</Col>
					</CardBody>
				</div>
			)}
		</>
	);
};

export default LiabilityTable;
