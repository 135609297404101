import React, { useState, useEffect } from "react";
import { Form, Button } from "reactstrap";

import formNavigationUtils from "../../../../../utils/formNavigationUtils";
import useCurrentCustomerStore from "../../../../../stores/CustomerStore";
import useCurrentDealStore from "../../../../../stores/DealStore";
import InputElement, {
	CurrencyInput,
	IntegerInputElement,
} from "../../../../forms/components/InputElement";
import InputWrapper from "../../../../forms/components/InputWrapper";

import { showApiError } from "../../../../../utils/errorRoutingUtils";
import { useFromUser } from "../../../../../stores/LocalStorageHelper";
import {
	saveFailAlert,
	saveSuccessAlert,
} from "../../../../../utils/alertUtils";

import { useFormContext } from "react-hook-form";

import shallow from "zustand/shallow";
import { saveLiability } from "../../../../../api/LiabilitiesApi";

const Liability = ({
	contactType,
	currentIndex,
	setCurrentIndex,
	toggle,
	custRecNum,
	liabilities,
	editLiabilities,
}) => {
	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// // Deal store
	// const { type } = useCurrentDealStore(
	// 	(state) => ({
	// 		type: state.deal.type,
	// 	}),
	// 	shallow
	// );
	// // Customer store
	// const { custRecNum, liabilities, editLiabilities } = useCurrentCustomerStore(
	// 	(state) => ({
	// 		custRecNum: state.ID,
	// 		liabilities: state.liabilities,
	// 		editLiabilities: state.editLiabilities,
	// 	}),
	// 	shallow
	// );

	const [collateralType, setCollateralType] = useState("");
	const [loanBalance, setLoanBalance] = useState(0);
	const [payment, setPayment] = useState(0);
	const [estAPR, setEstAPR] = useState(0);
	const [pmtsMades, setPmtsMades] = useState(0);
	const [pmtsRemain, setPmtsRemain] = useState(0);
	const [fico, setFico] = useState(0);

	// Toggles the modal

	const clearFields = () => {
		setCollateralType("");
		setLoanBalance(0);
		setPayment(0);
		setEstAPR(0);
		setPmtsMades(0);
		setPmtsRemain(0);
		setFico(0);
		setCurrentIndex(liabilities.length);
	};

	// Returns an updated store object with the new reference
	const addToLiabilitiesList = (liability, id) => {
		let obj = liabilities;
		liability.id = id;
		obj[currentIndex] = liability;

		return obj;
	};

	// Save new reference
	const newLiability = {
		ID: liabilities[currentIndex]?.ID || null,
		leadID:
			contactType === "Lead"
				? custRecNum
				: liabilities[currentIndex]?.leadID || null,
		creditAppID:
			contactType === "CreditApp"
				? custRecNum
				: liabilities[currentIndex]?.creditAppID || null,
		customerID:
			contactType === "Customer"
				? custRecNum
				: liabilities[currentIndex]?.creditAppID || null,
		collateralType,
		loanBalance,
		payment,
		estAPR,
		pmtsMades,
		pmtsRemain,
		fico,
		dealerID,
		locationID,
	};

	// Calls api to add the reference to database or update if it exists
	// If updating an sold customer's reference, just add the new obj to the store reference list
	const addLiability = () => {
		saveLiability(newLiability).then(
			(response) => {
				if (currentIndex != null) {
					editLiabilities(
						addToLiabilitiesList(newLiability, response.data.content.ID)
					);
				}
				clearFields();
				saveSuccessAlert();
				toggle();
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Get validation context
	const methods = useFormContext();

	useEffect(() => {
		console.log(liabilities[currentIndex]);
		setCollateralType(liabilities[currentIndex]?.collateralType || "");
		setLoanBalance(liabilities[currentIndex]?.loanBalance || 0);
		setPayment(liabilities[currentIndex]?.payment || 0);
		setEstAPR(liabilities[currentIndex]?.estAPR || 0);
		setPmtsMades(liabilities[currentIndex]?.pmtsMades || 0);
		setPmtsRemain(liabilities[currentIndex]?.pmtsRemain || 0);
		setFico(liabilities[currentIndex]?.fico || 0);
		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex]);

	return (
		<Form onKeyDown={formNavigationUtils}>
			<InputWrapper
				//formTitle={""}
				inputComponents={[
					<InputElement
						{...methods}
						value={collateralType}
						onChange={(e) => setCollateralType(e.target.value)}
						name="collateralType"
						label="Collateral Type"
						type="text"
					/>,

					<CurrencyInput
						{...methods}
						colSize="col-lg-3"
						value={loanBalance}
						name="loanBalance"
						label="Loan Balance"
						type="Currency"
						onChange={(e) => setLoanBalance(e)}
					/>,
					<CurrencyInput
						{...methods}
						colSize="col-lg-3"
						value={payment}
						name="Payment"
						label="Loan Payment"
						type="Currency"
						onChange={(e) => setPayment(e)}
					/>,
					<IntegerInputElement
						value={pmtsMades}
						onChange={(e) => setPmtsMades(e)}
						name="pmtsMades"
						label="Payments Mades"
						type="number"
					/>,
					<IntegerInputElement
						value={pmtsRemain}
						onChange={(e) => setPmtsRemain(e)}
						name="pmtsRemain"
						label="Payments Remain"
						type="number"
					/>,
					<IntegerInputElement
						value={fico}
						onChange={(e) => setFico(e)}
						name="fico"
						label="fico"
						type="number"
					/>,
				]}
				buttons={
					<div className="d-flex justify-content-center">
						<Button
							className="mr-1"
							color="primary"
							onClick={methods.handleSubmit(addLiability)}
						>
							Save
						</Button>
					</div>
				}
			/>
		</Form>
	);
};

export default Liability;
