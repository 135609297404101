import React, { useEffect, useState } from "react";

import formNavigationUtils from "utils/formNavigationUtils";
import useCurrentCustomerStore from "stores/CustomerStore";
import Attachment from "features/attachments/Attachment";
import BusinessDetails from "features/crm/subFeatures/contactTabs/detailsTab/BusinessDetails";
import CloneDealCustomerButton from "features/crm/helperFunctions/CloneDealCustomerButton";
import CustomerDetails from "features/crm/subFeatures/contactTabs/detailsTab/CustomerDetails";
import ChildrenModal from "components/modals/ChildrenModal";
import CustomerAddressHistoryTab from "features/crm/subFeatures/contactTabs/addressTab/CustomerAddressHistoryTab";
import CustomerEmployeeHistoryTab from "features/crm/subFeatures/contactTabs/employmentTab/CustomerEmployeeHistoryTab";
import CustomerInsuranceTab from "features/crm/subFeatures/contactTabs/insuranceTab/CustomerInsuranceTab";
import CustomerReferencesTab from "features/crm/subFeatures/contactTabs/referenceTab/CustomerReferencesTab";
import NavBar from "components/tabs/TabsHolder";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import { DealStatus } from "constants/Constants";
import { getAllCustomerInfo } from "api/CustomerAPI";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { FormProvider, useForm } from "react-hook-form";
import shallow from "zustand/shallow";
import UpdateDealCustomerButton from "features/crm/helperFunctions/UpdateDealCustomerButton";
import CurrentAddress from "features/crm/subFeatures/contactTabs/addressTab/CurrentAddress";
import Customer700CreditTab from "features/crm/subFeatures/contactTabs/credit700Tab/Customer700CreditTab";
import ZipCityTable from "components/tables/ZipCityTable";

const CustomerDealDetails = ({
	ID,
	editCustomer,
	editAddress,
	editMailAddress,
	editRecNum,
	toggle,
	cloneCustomer,
	isBuyer = false,
	type,
}) => {
	// Local states
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});

	// Opens and closes the tax modal
	const toggleCityModal = () => setCityModal(!cityModal);

	// Show modal containing cities with the 5 digit zip entered
	// Used to get the tax rate associated with a customer zip
	const handleZipChange = (zip, address) => {
		if (zip.length === 5) {
			setAddressData({ zip, address });
			toggleCityModal();
		}
	};

	// Customer store
	const {
		reset,
		isLoading,
		currentAddress,
		editID,
		editIsLoadingFalse,
		setCustomerFields,
		editCurrentAddress,
		editMailingAddress,
		editAddressHist,
		editEmpHist,
		editInsurance,
		editReferences,
		editCredit700Hist,
		editLiabilities,
		customerType,
	} = useCurrentCustomerStore(
		(state) => ({
			reset: state.reset,
			isLoading: state.isLoading,
			currentAddress: state.currentAddress,
			editID: state.editID,
			setCustomerFields: state.setCustomerFields,
			editCurrentAddress: state.editCurrentAddress,
			editMailingAddress: state.editMailingAddress,
			editIsLoadingFalse: state.editIsLoadingFalse,
			editAddressHist: state.editAddressHist,
			editEmpHist: state.editEmpHist,
			editInsurance: state.editInsurance,
			editReferences: state.editReferences,
			editCredit700Hist: state.editCredit700Hist,
			editLiabilities: state.editLiabilities,
			customerType: state.type,
		}),
		shallow
	);

	// Gets all customer's info, if we need to include more of the tabs, use
	// the data object returned from API call
	const getData = () => {
		console.log(ID);
		if (ID != null) {
			getAllCustomerInfo(ID).then(
				(res) => updateInfo(res.data.content),
				(err) => {
					console.log(err);

					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else {
			retrieveFailAlert();
			console.log("err3");
		}
	};

	// Updates the store information
	const updateInfo = (data) => {
		editID(data.customer.ID);
		setCustomerFields(data.customer);
		editCurrentAddress(data.currentAddress);
		editMailingAddress(data.mailingAddress);
		editAddressHist(data.addressHistory);
		editEmpHist(data.employmentHistory);
		editInsurance(data.insurance);
		editReferences(data.references);
		editCredit700Hist(data.credit700);
		editLiabilities(data.liabilities);
		editIsLoadingFalse();
	};

	useEffect(() => {
		getData();
		return () => {
			reset();
		};
		// eslint-disable-next-line
	}, []);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		firstName: yup.string().max(100),
		lastName: yup.string().required().max(100),
		contact: yup.string().max(25),
		source: yup.string().max(9),
		currentStreet: yup.string().max(50),
		currentCity: yup.string().max(50),
		mailStreet: yup.string().max(50),
		mailCity: yup.string().max(50),
		ssn: yup.string().matches(/^[0-9]{3}-[0-9]{2}-[0-9]{4}/, {
			message: "Must be 9 digits or empty",
			excludeEmptyString: true,
		}),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const personalTabs = [
		"Customer",
		"Address History",
		"Employment History",
		"Insurance",
		"References",
		"Credit Score",
		"Attachments",
	];

	const businessTabs = ["Business", "Insurance", "Attachments"];

	const buttonHandler = () => {
		if (!cloneCustomer) {
			return (
				<UpdateDealCustomerButton
					editCustomer={editCustomer}
					editAddress={editAddress}
					editMailAddress={editMailAddress}
					editRecNum={editRecNum}
					toggle={toggle}
					type={type}
				/>
			);
		} else {
			return (
				<CloneDealCustomerButton
					editCustomer={editCustomer}
					editAddress={editAddress}
					editMailAddress={editMailAddress}
					editRecNum={editRecNum}
					toggle={toggle}
				/>
			);
		}
	};

	const personalTabComponents = [
		<CustomerDetails />,
		<CustomerAddressHistoryTab isDeal={true} isBuyer={isBuyer}>
			<UpdateDealCustomerButton
				editCustomer={editCustomer}
				editAddress={editAddress}
				editMailAddress={editMailAddress}
				editRecNum={editRecNum}
				toggle={toggle}
			/>
		</CustomerAddressHistoryTab>,
		<CustomerEmployeeHistoryTab isDeal={true} />,
		<CustomerInsuranceTab />,
		<CustomerReferencesTab />,
		<Customer700CreditTab id={ID} />,
		<Attachment relatedID={ID} type="customer" />,
	];

	const businessTabComponents = [
		<>
			<BusinessDetails />
			<CurrentAddress getCities={handleZipChange} />
			<ChildrenModal
				modal={cityModal}
				toggle={toggleCityModal}
				modalTitle="Cities"
			>
				<ZipCityTable
					data={addressData}
					toggle={toggleCityModal}
					address={currentAddress}
					setAddress={editCurrentAddress}
				/>
			</ChildrenModal>
		</>,
		<CustomerInsuranceTab />,
		<Attachment relatedID={ID} type="customer" />,
	];
	return (
		<div onKeyDown={formNavigationUtils}>
			{!isLoading && (
				<FormProvider {...methods}>
					{customerType === 0 || customerType === null ? (
						<NavBar
							navClass="nav-pills-primary justify-content-center nav nav-pills"
							tabs={personalTabs}
							tabComponents={personalTabComponents}
						/>
					) : (
						<NavBar
							navClass="nav-pills-primary justify-content-center nav nav-pills"
							tabs={businessTabs}
							tabComponents={businessTabComponents}
						/>
					)}
					{type === DealStatus.PENDING && buttonHandler()}
				</FormProvider>
			)}
		</div>
	);
};

export default CustomerDealDetails;
