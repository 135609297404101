import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl } from "../constants/Constants";
//import { LOCAL } from "./Constants";

// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL,
// });
const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/customer/",
	//baseURL: LOCAL,
});

// Get customer
export const getCustomerDetails = async (ID) => {
	return await instance.get(`${ID}`);
	//return await localInstance.get(`${ID}`);
};

// Get customer list
export const getCustomerList = async (dealerID, locationID) => {
	return await instance.get(`list/${dealerID}/${locationID}`);
};
// Get customer list
export const getCustomerListByDateRange = async (
	locationID,
	fromDate,
	toDate
) => {
	return await instance.get(
		`list/${locationID}?fromDate=${fromDate}&toDate=${toDate}`
	);
};
// Get customer list by filter

export const getCustomerListBySearch = async (locationID, filter) => {
	return await instance.post(`ListBySearch/${locationID}`, { filter });
	//return await localInstance.post(`ListBySearch/${locationID}`, { filter });
};

// Get customer list by status
export const getCustomerListByStatus = async (dealerID, locationID, status) => {
	return await instance.get(`list/${dealerID}/${locationID}/${status}`);
};

// Save customer
export const saveCustomer = async (body) => {
	return await instance.post(`save`, body);
};

// Get customer's address
export const getAddress = async (ID) => {
	return await instance.get(`address/${ID}`);
};

// Get customer's address list
export const getAddressHistory = async (ID) => {
	return await instance.get(`address/list/${ID}`);
};

// Delete a customer's address
export const deleteAddress = async (ID) => {
	return await instance.post(`address/delete/${ID}`, null);
};

// Save a customer's address
export const saveAddressHistory = async (body) => {
	return await instance.post(`address/save`, body);
};

// Gets a list of a customer's employment history
export const getEmployeeHistory = async (ID) => {
	return await instance.get(`employment/list/${ID}`);
};

// Delete a customer's employment history
export const deleteEmploymentHistory = async (ID) => {
	return await instance.post(`employment/delete/${ID}`, null);
};

// Save a customer's employment history
export const saveEmployeeHistory = async (body) => {
	return await instance.post(`employment/save`, body);
};

// Gets a customer's insurance info
export const getInsurance = async (custRecNum) => {
	return await instance.get(`insurance/${custRecNum}`);
};

// Saves a customer's insurance info
export const saveInsurance = async (body) => {
	return await instance.post(`insurance/save`, body);
};

// Gets a customer's list of references
export const getReferences = async (custRecNum) => {
	return await instance.get(`reference/${custRecNum}`);
};

// Saves a customer's references
export const saveReferences = async (body) => {
	return await instance.post(`reference/save`, body);
};

// Deletes a customer's reference
export const deleteReference = async (id) => {
	return await instance.post(`reference/delete/${id}`, null);
};

// Gets all the customer's tab information
export const getAllCustomerInfo = async (custRecNum) => {
	return await instance.get(`info/${custRecNum}`);
	//return await localInstance.get(`info/${custRecNum}`);
};

// Updates a customer's information who is in a sold deal
export const updateSoldCustomer = async (customerInfo) => {
	return await instance.post(`update-sold`, customerInfo);
};

// Gets customer's current address
export const getCustomersCurrentAddress = async (custRecNum) => {
	return await instance.get(`address/current/${custRecNum}`);
};

// Gets customer's  Mailing Address
export const getCustomersMailingAddress = async (custRecNum) => {
	return await instance.get(`address/mailing/${custRecNum}`);
};

// Get lead list
export const getLeadsList = async (dealerID, locationID) => {
	return await instance.get(`lead/list/${dealerID}/${locationID}`);
};

// Save customer
export const saveJustCustomer = async (customer) => {
	return await instance.post(`save-customer`, customer);
};
