/**
 * Sends a chat completion request to the Make.com webhook.
 *
 * @param {Array} messages - Array of message objects for the conversation.
 * @param {string} dealerID - The dealer ID.
 * @param {string} locationID - The location ID.
 * @returns {Promise<Object>} The response from the Make.com webhook, containing the AI's response.
 * @throws {Error} Throws an error if the request fails.
 */
export const fetchChatCompletion = async (messages, dealerID, locationID) => {
	try {
		const userMessage = messages[messages.length - 1]?.content || "";

		const response = await fetch(
			"https://dealerclick-ai-1000669388216.us-central1.run.app/query",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					question: userMessage,
					dealerID, // Include dealerID
					locationID, // Include locationID
				}),
			}
		);

		const rawResponse = await response.text();

		if (!response.ok) {
			throw new Error(`HTTP ${response.status}: ${rawResponse}`);
		}

		const data = JSON.parse(rawResponse);

		return {
			content: data.answer || "[No response content]",
			role: "assistant",
		};
	} catch (error) {
		console.error("Error fetching chat completion:", error.message);
		throw error;
	}
};

/**
 * Chat roles supported by the OpenAI GPT API.
 */
export const ChatRole = {
	SYSTEM: "system",
	USER: "user",
	ASSISTANT: "assistant",
};

/**
 * Creates a properly formatted message object for chat API requests.
 *
 * @param {string} role - The role of the message sender (system, user, assistant).
 * @param {string} content - The content of the message.
 * @returns {Object} Formatted message object.
 */
export const createChatMessage = (role, content) => ({
	role,
	content,
});
