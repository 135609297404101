import React from "react";
import { Card, CardBody, Col, Row, Input, Label, Button } from "reactstrap";
import { DealStatus, SaleType } from "constants/Constants";
import useCurrentDealStore from "stores/DealStore";
import useCurrentSaleStore from "stores/SaleStore";
import formNavigationUtils from "utils/formNavigationUtils";
import shallow from "zustand/shallow";
import DealSalesmanDropDown from "../../dealDetails/DealSalesmanDropdown";
import LenderDetail from "../../dealDetails/LenderDetail";
export const DealInformation = () => {
	const { type } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			carRecNum: state.deal.carRecNum,
			type: state.deal.type,
		}),
		shallow
	);

	// State from sale store
	const { loanDate, editLoanDate, dealNumber, editDealNumber, saleType } =
		useCurrentSaleStore(
			(state) => ({
				loanDate: state.loanDate,
				editLoanDate: state.editLoanDate,
				dealNumber: state.dealNumber,
				editDealNumber: state.editDealNumber,
				saleType: state.saleType,
			}),
			shallow
		);
	console.log(saleType);
	const typeOfSale = SaleType[saleType]?.label;

	return (
		<Card>
			<CardBody className="pb-2">
				<Col className="mb-3 w-100">
					<Button
						disabled
						type="button"
						style={{ color: "black" }}
						className="btn-round btn-md btn w-100 btn-outline-secondary my-0"
					>
						{typeOfSale} {type}
					</Button>
				</Col>
				<Row
					className="mx-0 pt-0"
					style={{ marginBottom: "2px" }}
					onKeyDown={formNavigationUtils}
				>
					<Col xl="6">
						<Label>Deal Number</Label>
						<Input
							readOnly={type !== DealStatus.PENDING}
							type="text"
							name="dealNumber"
							value={dealNumber}
							onChange={(e) => editDealNumber(e.target.value)}
						/>
					</Col>
					<Col xl="6">
						<Label>Date of Sale</Label>
						<Input
							readOnly={type !== DealStatus.PENDING}
							type="date"
							name="dealLoanDate"
							value={loanDate}
							onChange={(e) => editLoanDate(e.target.value)}
						/>
					</Col>
					<Col xl="12" className="mt-2 pt-2">
						<LenderDetail />
					</Col>
					<Col xl="12">
						<DealSalesmanDropDown />
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};
export default DealInformation;
