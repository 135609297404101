import React, { useState } from "react";
import useCurrentDealStore from "stores/DealStore";
import useRoute from "hooks/useRoute";
import { showApiError } from "utils/errorRoutingUtils";

import { useHistory } from "react-router-dom";
import { PATHS } from "constants/Constants";
import { DealStatus } from "constants/Constants";
import {
	saveDeal,
	deleteDeal,
	unwindDealv2,
	transferDealToSoldv2,
} from "api/DealAPI";
import {
	twoOptionsAlert,
	deleteSuccessAlert,
	saveFailAlert,
	failAlert,
	updateSuccessAlert,
	confirmTransferToSold,
} from "utils/alertUtils";

import shallow from "zustand/shallow";
import useSetDeal from "features/deal/hooks/useSetDeal";
import dayjs from "dayjs";
import ChildrenModal from "components/modals/ChildrenModal";
import useBuildDeal from "features/deal/hooks/useBuildDeal";
import { transferDealToBHPH } from "../../../../account/AccountAPI";
import { LenderFeeForm } from "../recap/LenderFeeForm";

export const SaveButton = () => {
	//Route
	const newRoute = useRoute(PATHS.DEAL_ADD);

	const getDeal = useBuildDeal();

	const history = useHistory();
	//const dealDetails = useRoute(PATHS.DEAL_DETAIL);
	const setDeal = useSetDeal();
	// Deal store
	const { type, editIsLoading } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			editIsLoading: state.editIsLoading,
		}),
		shallow
	);
	// Save deal here
	const saveData = () => {
		if (type === DealStatus.SOLD) {
			failAlert(
				"Unable to save a sold deal. Unwind the deal first if deal information needs to be updated."
			);
			return;
		} else if (type === DealStatus.DELETED) {
			failAlert("Unable to save a delete deal.");
			return;
		}
		editIsLoading(true);
		const deal = getDeal();
		console.log(deal);
		saveDeal(deal).then(
			(response) => {
				setDeal(response.data.content);
				editIsLoading(false);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
				history.push(newRoute);
			}
		);
	};
	return (
		<>
			<div
				className="btn btn-success float-button"
				onClick={saveData}
				readOnly={type !== DealStatus.PENDING}
			>
				<i className="nc-icon nc-check-2" /> Save
			</div>
		</>
	);
};

export const DeleteButton = () => {
	const history = useHistory();
	//const dealAdd = useRoute(PATHS.DEAL_ADD);
	const dealList = useRoute(PATHS.DEAL_LIST);
	// Deal store
	const { ID, type, editType } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			type: state.deal.type,
			editType: state.editType,
		}),
		shallow
	);

	// Delete Data
	const deleteData = () => {
		//For now we just change the status of delete
		//later we can use below code for two option delete
		//--------------------------------------------------
		let confirmDelete = "Cancel";
		type === DealStatus.PENDING &&
			twoOptionsAlert("Do you want to delete it ?", "Yes", "No").then((res) => {
				confirmDelete = res;
				console.log(ID);
				if (confirmDelete === "Yes") {
					deleteDeal(ID, 0).then(
						(response) => {
							console.log(response);
							updateSuccessAlert();
							editType(DealStatus.DELETED);
						},
						(error) => {
							console.error(error);
							if (!error.isGeneralError) {
								showApiError(error, failAlert);
							} else {
								showApiError();
							}
						}
					);
				}
			});
		type === DealStatus.DELETED &&
			twoOptionsAlert(
				"Do you want to delete it ?",
				"Delete it from system",
				"Cancel"
			).then((res) => {
				confirmDelete = res;
				console.log(ID);
				if (confirmDelete === "Delete it from system") {
					deleteDeal(ID, 1).then(
						(response) => {
							console.log(response);
							deleteSuccessAlert();
							history.push(dealList);
						},
						(error) => {
							console.error(error);
							if (!error.isGeneralError) {
								showApiError(error, failAlert);
							} else {
								showApiError();
							}
						}
					);
				}
			});
	};
	return (
		<>
			{(type === DealStatus.PENDING || type === DealStatus.DELETED) && (
				<button className="btn-danger btn btn-md" onClick={deleteData}>
					<i className="nc-icon nc-simple-remove"></i> Delete
				</button>
			)}
		</>
	);
};

export const TransferToSoldButton = () => {
	const setDeal = useSetDeal();
	const getDeal = useBuildDeal();
	// Deal store
	const {
		type,
		editIsLoading,
		vehicle,
		inventoryID,
		carRecNum,
		buyer,
		dealID,
		wholesaleInvs,
	} = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			editSoldDate: state.editSoldDate,
			editType: state.editType,
			editIsLoading: state.editIsLoading,
			vehicle: state.vehicle,
			inventoryID: state.vehicle?.inventoryID,
			carRecNum: state.deal.carRecNum,
			buyer: state.buyer,
			dealID: state.deal.ID,
			wholesaleInvs: state.wholesaleInvs,
		}),
		shallow
	);

	// Transfer the deal to sold
	const transferToSold = async () => {
		const option = await twoOptionsAlert(
			"Are you sure you want to transfer deal to sold?",
			"Yes",
			"Cancel"
		);

		if (option === "Cancel") {
			return;
		}

		editIsLoading(true);
		const deal = getDeal();

		saveDeal(deal).then(
			(res) => {
				transferDealToSoldv2(dealID).then(
					(res2) => {
						setDeal(res2.data.content);
						editIsLoading(false);
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, failAlert);
						}
						editIsLoading(false);
					}
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
				editIsLoading(false);
			}
		);
	};
	return (
		<div
			className="btn btn-primary btn-md onMobileScreen"
			onClick={() => {
				if (buyer?.ID === null || Object.keys(buyer).length === 0) {
					failAlert("Cannot Transfer to Sold without a Buyer");
				} else {
					if (
						wholesaleInvs.length === 0 &&
						(inventoryID === null ||
							vehicle === null ||
							carRecNum === null ||
							Object.keys(vehicle).length === 0)
					) {
						console.log(carRecNum);
						confirmTransferToSold(transferToSold);
					} else {
						transferToSold();
					}
				}
			}}
			readOnly={type !== DealStatus.PENDING}
		>
			<i className="nc-icon nc-minimal-right"></i> Transfer to Sold
		</div>
	);
};

export const UnwindButton = () => {
	const setDeal = useSetDeal();

	// Deal store
	const { dealID, type, editIsLoading } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			editIsLoading: state.editIsLoading,
			dealID: state.deal.ID,
		}),
		shallow
	);

	// Unwinds the deal
	const unwind = async () => {
		const option = await twoOptionsAlert(
			"Are you sure you want to unwind this deal?",
			"Yes",
			"Cancel"
		);

		if (option === "Cancel") {
			return;
		}

		editIsLoading(true);

		unwindDealv2(dealID).then(
			(res) => {
				setDeal(res.data.content);
				editIsLoading(false);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
				editIsLoading(false);
			}
		);
	};
	return (
		<>
			<div
				className="btn btn-primary-dark btn-sm"
				onClick={unwind}
				readOnly={type !== DealStatus.SOLD}
			>
				<i className="nc-icon nc-minimal-left"></i> Unwind
			</div>
		</>
	);
};

export const TransferToFundedButton = () => {
	const getDeal = useBuildDeal();
	const [open, setOpen] = useState(false);

	const toggle = () => {
		setOpen(!open);
	};

	const setDeal = useSetDeal();
	const today = dayjs().format("YYYY-MM-DD");
	// Deal store
	const { type, editIsLoading, lender } = useCurrentDealStore(
		(state) => ({
			lender: state.lender,
			type: state.deal.type,
			editIsLoading: state.editIsLoading,
		}),
		shallow
	);

	// Transfer the deal to funded
	const transferToFunded = async () => {
		const option = await twoOptionsAlert(
			"Are you sure you want to transfer deal to funded? You won't be able to delete or rewind it.",
			"Yes",
			"Cancel"
		);

		if (option === "Cancel") {
			return;
		}
		const deal = getDeal();
		editIsLoading(true);

		// if transfer to funded requires any more logic and rules we should probably handle that on the backend
		// performing a deal save and then calling the endpoint would be the best way of doing this

		let modifiedDeal = { ...deal };
		modifiedDeal.deal.type = DealStatus.FUNDED;
		modifiedDeal.deal.fundedDate = today;

		modifiedDeal.deal.isFunded = true;
		modifiedDeal.deal.fundedDate = today;

		saveDeal(modifiedDeal).then(
			(res) => {
				console.log(res.data.content);
				setDeal(res.data.content);
				editIsLoading(false);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
				editIsLoading(false);
			}
		);
	};

	return (
		<>
			<div
				className="btn btn-primary btn-sm"
				onClick={() => {
					if (lender === null) transferToFunded();
					else toggle();
				}}
				readOnly={type !== DealStatus.SOLD}
			>
				Transfer to Funded<i className="nc-icon nc-minimal-right"></i>
			</div>
			<ChildrenModal
				onClosed={transferToFunded}
				modal={open}
				toggle={toggle}
				size="s"
				modalTitle="Lender Fees"
				children={<LenderFeeForm />}
			/>
		</>
	);
};
//==================
// Transfer deal to BHPH
export const TransferToBHPHButton = () => {
	const getDeal = useBuildDeal();
	const deal = getDeal();

	const setDeal = useSetDeal();
	const today = dayjs().format("YYYY-MM-DD");
	// Deal store
	const { type, editIsLoading } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			editIsLoading: state.editIsLoading,
		}),
		shallow
	);
	const transferToBHPH = async () => {
		const option = await twoOptionsAlert(
			"Are you sure you want to transfer deal to BHPH?",
			"Yes",
			"Cancel"
		);

		if (option === "Cancel") {
			return;
		}

		editIsLoading(true);
		const deal = getDeal();
		transferDealToBHPH(deal.deal.ID).then(
			(res) => {
				let modifiedDeal = { ...deal };
				modifiedDeal.deal.type = DealStatus.FUNDED;
				modifiedDeal.deal.fundedDate = today;
				modifiedDeal.deal.isFunded = true;
				modifiedDeal.deal.fundedDate = today;
				saveDeal(modifiedDeal).then(
					(res) => {
						console.log(res.data.content);
						setDeal(res.data.content);
						editIsLoading(false);
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, failAlert);
						}
						editIsLoading(false);
					}
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}

				editIsLoading(false);
			}
		);
	};
	return (
		<div
			className="btn btn-primary btn-sm"
			onClick={() => {
				if (deal.buyer?.ID === null || Object.keys(deal.buyer).length === 0) {
					failAlert("Cannot Transfer to BHPH without a Buyer");
				} else {
					transferToBHPH();
				}
			}}
			readOnly={type !== DealStatus.SOLD}
		>
			Transfer to BHPH<i className="nc-icon nc-minimal-right"></i>
		</div>
	);
};
