import React from "react";
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";
import shallow from "zustand/shallow";
import PropTypes from "prop-types";

import { DealStatus } from "constants/Constants";
import useCurrentDealStore from "stores/DealStore";
import InventoryListModal from "./InventoryListModal";
import Camera from "assets/img/placeholder.png";
import Swal from "sweetalert2"; // Import SweetAlert
import "sweetalert2/dist/sweetalert2.min.css"; // Import SweetAlert styles
import useCurrentSaleStore from "../../../../stores/SaleStore";

const formatNumber = (num) => {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const DealInventoryHeader = () => {
	const { vehicle, type } = useCurrentDealStore(
		(state) => ({
			vehicle: state.vehicle,
			type: state.deal.type,
		}),
		shallow
	);
	const { saleType } = useCurrentSaleStore(
		(state) => ({
			saleType: state.saleType,
		}),
		shallow
	);

	const handleCopyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				Swal.fire({
					icon: "success",
					title: "Copied!",
					text: `'${text}' has been copied to the clipboard.`,
					timer: 1500,
					showConfirmButton: false,
				});
			})
			.catch((err) => {
				console.error("Could not copy text: ", err);
			});
	};
	console.log(saleType);
	if (saleType === 3) {
		return;
	} else {
		return (
			<>
				{type === DealStatus.PENDING && vehicle?.inventoryID == null ? (
					<div className="text-center card-plain mt-2">
						<CardTitle tag="h3">Add Vehicle</CardTitle>
						<Col className="pb-4 px-0 text-center mt-3 pt-1">
							<InventoryListModal modalTitle="Select Contact" />
						</Col>
					</div>
				) : vehicle?.inventoryID != null ? (
					<Card>
						<CardBody>
							<div
								className="mb-2"
								style={{ display: "flex", justifyContent: "space-between" }}
							>
								<div
									style={{ color: "black", cursor: "pointer" }}
									className="btn-round btn-md btn btn-outline-secondary"
									onClick={() => handleCopyToClipboard(vehicle.stockNo)}
								>
									#{vehicle.stockNo}
								</div>
								<div
									style={{ color: "black", cursor: "pointer" }}
									className="btn-round btn-md btn btn-outline-secondary"
									onClick={() => handleCopyToClipboard(vehicle.vin)}
								>
									{vehicle.vin}
								</div>
							</div>
							<div className="heading-container">
								<h4 className="text-center mb-3 mt-2">
									{vehicle.year + " " + vehicle.make + " " + vehicle.model}
								</h4>
							</div>
							<Row className="justify-content-center mb-3 body-container">
								<Col style={{ minHeight: "128px" }} xs="6" className="p-0">
									<img
										src={`${vehicle.thumbnail}?lastmod=${Date.now()}` || Camera}
										alt="Vehicle"
										className="customer-profile mb-2"
										onError={(e) => {
											e.target.onerror = null;
											e.target.src = Camera;
										}}
									/>
								</Col>
								<Col col="6" className="text-center">
									<div className="h-100 flex-column d-flex align-items-center justify-content-center">
										<CardText style={{ fontSize: "14px", marginBottom: 0 }}>
											Asking Price:&nbsp;
										</CardText>
										<CardText style={{ fontSize: "1.3rem" }}>
											{isNaN(vehicle.askingPrice) ||
											vehicle.askingPrice === 0 ||
											vehicle.askingPrice === null
												? "N/A"
												: "$" + formatNumber(vehicle.askingPrice)}
										</CardText>
									</div>
								</Col>
							</Row>
							<InventoryListModal modalTitle="Select Contact" />
						</CardBody>
					</Card>
				) : (
					<p className="d-flex text-danger">
						No Vehicle was added to this deal
					</p>
				)}
			</>
		);
	}
};

DealInventoryHeader.propTypes = {
	vehicle: PropTypes.shape({
		year: PropTypes.number,
		make: PropTypes.string,
		model: PropTypes.string,
		thumbnail: PropTypes.string,
		stockNo: PropTypes.string,
		vin: PropTypes.string,
		dateIn: PropTypes.string,
		dateOut: PropTypes.string,
	}),
	type: PropTypes.string,
};

export default DealInventoryHeader;
