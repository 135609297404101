import React from "react";
import "./UnauthorizedContent.css";

/**
 * UnauthorizedContent Component
 *
 * This component renders a message indicating that the user is not authorized
 * to access the content.
 *
 * @returns {JSX.Element} The UnauthorizedContent component
 */
const UnauthorizedContent = () => (
	// Main container with flex layout to center content
	<div className="unauthorized-content">
		{/* Warning icon */}
		<i className="fa fa-exclamation-triangle text-warning unauthorized-content__icon" />

		{/* Unauthorized text message */}
		<h3 className="text-center">
			This user is not authorized to access this content
		</h3>
	</div>
);

export default UnauthorizedContent;
