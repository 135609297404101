import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row, Input, Label, Button } from "reactstrap";
import DealInventoryHeader from "../../inventory/DealInventoryHeader";
import Sale from "../../sale/Sale";
import Summary from "../../summary/Summary";
import TermsTable from "../../paymentOptions/TermsTable";
import { DealStatus, SaleType } from "constants/Constants";
import useCurrentDealStore from "stores/DealStore";
import useCurrentSaleStore from "stores/SaleStore";
import formNavigationUtils from "utils/formNavigationUtils";
import shallow from "zustand/shallow";
import DealSalesmanDropDown from "../../dealDetails/DealSalesmanDropdown";
import LenderDetail from "../../dealDetails/LenderDetail";
import DealBuyerSwitchButton from "features/deal/components/DealBuyerSwitchButton";
import DealWholesaleInvsList from "../wholesaleInventorys/DealWholesaleInvsList";
import BulkSale from "../../sale/BulkSale";
import DealInformation from "./DealInformation";

/**
 * Component for displaying the content of a deal, including buyer info, deal details, and related sections.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const BullkRetailTab = () => {
	// State from deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			carRecNum: state.deal.carRecNum,
			type: state.deal.type,
		}),
		shallow
	);

	// State from sale store
	const { saleType } = useCurrentSaleStore(
		(state) => ({
			saleType: state.saleType,
		}),
		shallow
	);
	console.log(saleType);

	return (
		<>
			<Row className="d-flex align-items-start">
				<Col lg="4" className="text-center mb-2-sm">
					<Card className="h-100 card-plain mt-0">
						<div className="p-0 d-flex flex-column">
							<DealBuyerSwitchButton saleType={saleType} />
						</div>
					</Card>
				</Col>

				<Col lg="5">
					<DealWholesaleInvsList />
				</Col>
				<Col lg="3">
					<DealInformation />
				</Col>
			</Row>

			<hr className="my-3 mx-3" />
			{/* <Col lg="12">
				<Card className="h-100 card-plain mt-0 mb-2-sm">
					<div>
						<DealWholesaleInvsList />
					</div>
				</Card>
			</Col> */}
			<Row>
				<Col lg="4">
					<Card>
						<Sale />
					</Card>
				</Col>
				<Col lg="4">
					<Card>
						<TermsTable />
					</Card>
				</Col>
				<Col lg="4">
					<Card>
						<Summary />
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default BullkRetailTab;
